import React from "react";

export default function Mitigation() {
  return (
    <>
      <div className="page-content">
        <h1>
          Become a Mitigation Agent
        </h1>
        <article className="article-content">
          <div className="flex justify-center">
            <blockquote className="mx-5 p-5 border-l-2 border-zinc-900 dark:border-zinc-400">
              <i>
                <p>
                  "The process or result of making something less severe,
                  dangerous, painful, harsh, or damaging."
                  <br />
                  <br />
                  <a
                    className="hover:link font-bold"
                    href="https://www.merriam-webster.com/dictionary/mitigation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    - Link to Definition
                  </a>
                </p>
              </i>
            </blockquote>
          </div>
          <p className="mt-5">
            This section is a simple nod to the origins of the organization and
            site. A mostly tongue-in-cheek reference to <i>The Division</i>.
            Becoming a Mitigation Agent is a fairly simple matter. It's similar
            to a boy-scouts pledge, a creed if you will. Anyone can embark on
            being a Mitigation Agent, but it requires a great deal of
            introspection if you are able to.
          </p>
          <br />
          <p>
            You are pledging to help, to the best of your ability, an
            individual, family, or community affected by a crisis as long as it
            is reasonable to do so.
          </p>
          <br />

          <p>
            You are pledging to ignore an individual's race, religion, sex,
            political affiliation, and economic status when they are in genuine,
            life-threatening need, and likewise, if someone asks for your help
            in how to prepare for a crisis. Disasters don't care about any of
            those categories. There are far more things in common among people
            than differences.
          </p>
          <br />
          <p>
            You are pledging to the best of your ability, pursue training that
            makes you qualified to render aid to an afflicted individual.
          </p>
          <br />
          <p>
            You are pledging to not interfere with official Emergency Responders
            when they arrive on the scene.
          </p>
          <br />
          <p>
            There is a dashboard in development to track the various
            requirements and areas of improvement, ranging from having supplies
            on hand to keeping up with financial security and physical fitness.
            No data will be collected; it's a way to simply check a box and show
            the progress to being an Advanced Agent.
          </p>
          <br />
          <p>
            Being a Mitigation Agent means that you are personally pledging to
            try and be ready, willing, and able to assist others when disaster
            strikes. It could be something as simple as helping remove branches
            after a severe storm, or knowing how to perform CPR in case the need
            arises.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="CPR Demonstration"
            src="https://images.unsplash.com/photo-1622551546704-36926ae49878?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80 "
          />
          <br />
          <p>
            It also means taking a personal inventory and seeing if you are able
            and willing to assist in a crisis. There is no shame in admitting
            that perhaps some things are physically or mentally too challenging
            to pursue. Knowing your limits takes more strength than false
            bravado.
          </p>
          <br />
          <p>
            That is what being a Mitigation Agent is. Reducing the negative
            impact of a disaster on a person, family, or community. If you want
            to do that, or even work towards being able to do that…Welcome,
            Mitigation Agent.
          </p>
          <br />
          <p className="mb-5">
            Upcoming features include an Advanced Mitigation Agent dashboard
            where you will be able to track your personal progress with
            suggested trainings and also be notified of worldwide events.
          </p>
        </article>
      </div>
    </>
  );
}
