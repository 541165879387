import React from "react";

export default function Water() {
  return (
    <>
      <div className="grid justify-items-center text-left animate-appear">
        <h1 className="text-3xl font-bold text-center text-primary m-3">
          Water
        </h1>
        <div className="mb-3 w-3/4 sm:w-1/2 text-zinc-600 dark:text-neutral-content">
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Water Filtration/Purification
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3movb9a"
                target="_blank"
                rel="noreferrer"
              >
                Potable Aqua (tablets)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3su9GaS"
                target="_blank"
                rel="noreferrer"
              >
                Sawyer Mini Water Filter
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3qfaVYW"
                target="_blank"
                rel="noreferrer"
              >
                Sawyer Squeeze System
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3eh10MG"
                target="_blank"
                rel="noreferrer"
              >
                Katadyn Hiker Pro Transparent Water Filter
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/32hAz7h"
                target="_blank"
                rel="noreferrer"
              >
                MSR MiniWorks EX Microfilter Water Filter
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3ebOdv2"
                target="_blank"
                rel="noreferrer"
              >
                Lifestraw Home Pitcher
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://lifestraw.com/products/lifestraw-home-dispenser"
                target="_blank"
                rel="noreferrer"
              >
                Lifestraw Home Dispenser
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3eeuRW5"
                target="_blank"
                rel="noreferrer"
              >
                Spare Filters (Lifestraw 3 Pack)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3ejrI7i"
                target="_blank"
                rel="noreferrer"
              >
                1 Year Supply of Filters (Lifestraw)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3edEft1"
                target="_blank"
                rel="noreferrer"
              >
                LifeStraw Family 1.0 Portable Gravity Powered Water Purifier
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3J9iZmz"
                target="_blank"
                rel="noreferrer"
              >
                LifeStraw Flex Advanced Water Filter with Gravity Bag
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3moFOcc"
                target="_blank"
                rel="noreferrer"
              >
                LifeStraw Mission Water Purification System
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Water Storage
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3FjmIM5"
                target="_blank"
                rel="noreferrer"
              >
                Datrex Emergency Water Packet 4.227 oz 3 Day/72 Hour Supply
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3Ja1BOv"
                target="_blank"
                rel="noreferrer"
              >
                Datrex Emergency Survival Water Pouch (Pack of 64), 125ml
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3Fi4jPO"
                target="_blank"
                rel="noreferrer"
              >
                Reliance Products Aqua-Tainer 4 Gallon Rigid Water Container
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3pfaoqA"
                target="_blank"
                rel="noreferrer"
              >
                Reliance Products Aqua-Tainer 7 Gallon Rigid Water Container
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3EjjM0o"
                target="_blank"
                rel="noreferrer"
              >
                Reliance Products Aqua-Tainer 7 Gallon (4 pack)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3H0bJHR"
                target="_blank"
                rel="noreferrer"
              >
                Augason Farms Water Storage Barrel 55-Gallon Drum
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3sqySyT"
                target="_blank"
                rel="noreferrer"
              >
                Augason Farms 6-07910 Water Filtration and Storage Kit 55 Gallon
              </a>
            </li>
          </ul>
          <br />
          <p className="mb-5">
            Affiliate Link Reminder: To keep this site ad-free, the links in the
            store are affiliate-based and rely on cookies. A segment of any
            purchase made after clicking the link (any product), will give a
            small percentage kickback to the site. Thank you for helping keep
            this site ad-free!
          </p>
        </div>
      </div>
    </>
  );
}
