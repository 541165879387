import React from "react";
/* import { condRender } from "../../src/utils/helpers"; */

export default function Hero() {
  /* const currentDate = `${new Date().getMonth() + 1}/${new Date().getDate()}`; */
  return (
    <div className="hero min-h-screen bg-hero-img -mt-[64px]">
      <div className="w-full sm:w-3/4 lg:w-1/2 sm:rounded-lg bg-white dark:bg-zinc-900 dark:bg-opacity-80 bg-opacity-80 sm:p-8 mt-[68px] animate-appear">
        <div className="hero-content text-left">
          <div>
            <h1 className="text-6xl font-bold text-center text-primary dark:text-neutral-content">
              Pick Up A Piece
            </h1>
            <img
              className="mx-auto mt-6"
              src={require("../assets/images/puap_logo.png")}
              alt="Pick Up A Piece Logo"
              width="150px"
              height="150px"
              draggable="false"
            />
            <p className="py-6 text-lg text-slate-800 dark:text-neutral-content">
              <div className="flex justify-center">
                <blockquote className="mx-5 px-5 border-l-2 border-zinc-900 dark:border-zinc-400">
                  <i>
                    <p>
                      "Tragedy is invisible. People turn away from it, they run
                      from it if they can.
                      <br />
                      <br />
                      And it's hard knowing you belong here. That your purpose
                      lies amongst all this pain. But someone's gotta be there.
                      To pick it up, to push back, to put the first piece back
                      together.
                      <br />
                      <br />
                      To put us all back together."
                      <br />
                      <br />
                      Tom Clancy's The Division
                    </p>
                  </i>
                </blockquote>
              </div>
              <br />
              <p>
                Pick Up A Piece is a self-reliance hub of information and
                like-minded individuals, dedicated to preparing for life's many
                troubles, big or small. Encouraging resilient individuals,
                families, and communities to help pick up a piece of the world
                when it falls apart.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
