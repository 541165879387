import React from "react";

export default function Wildfire() {
  return (
    <>
      <div className="page-content">
        <h1>
          Wildfires
        </h1>
        <article className="article-content">
          <p className="mt-3">
            As the climate warms worldwide, wildfires are, and will be an
            increasingly common disaster. These massive blazes can cause
            widespread disruption and death; this is a disaster you cannot fight
            head-on. The crucial steps to surviving this disaster include -
          </p>
          <ul className="list-disc list-inside mx-4">
            <li>Having a plan</li>
            <li>Fortifying your home</li>
            <li>Obtaining emergency supplies</li>
            <li>
              Listen to evacuation orders & have go-bags (bug-out bags) ready
            </li>
          </ul>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Wildfire"
            src="https://images.pexels.com/photos/266558/pexels-photo-266558.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <p>
            A wildfire is a large fire, either started by humans or natural
            causes, that spreads rapidly over woodland or brush. While these
            fires can move slowly, they can reach top speeds of over 14mph/22kpm
            or faster if fueled by wind. Factors that affect a fire include
            fuel, wind speed, slope, and man-made structures.
          </p>
          <br />
          <p>
            Due to incorrect knowledge about suppressing wildfires, fires
            nowadays are larger, burn hotter, and occur more frequently than
            they do naturally. A natural fire would burn frequently, be smaller
            in size, and burn at a cooler temperature. Some trees actually
            depend on fire to seed, and fire can refresh and revitalize a forest
            as it burns through naturally.
          </p>
          <br />
          <p>
            Unfortunately, the increased fuel load (due to constant
            extinguishing efforts) and not managing the forests correctly over
            many decades has caused an imbalance. The massive fires that we see
            nowadays, instead of rejuvenating a forest, completely destroy it.
          </p>
          <br />
          <p>
            Most wildfires are started by human activity (90% or so). Ignition
            sources include unsecured trailer chains, cigarettes, fireworks,
            campfires, and unintentional ignition. Part of being prepared for a
            wildfire is ensuring you don't start one!
          </p>
          <br />
          <p>
            The top 10 U.S states at risk for wildfires are listed below. As the
            climate warms, expect more states that usually aren't at risk to
            increase in likelihood for this event.
          </p>
        </article>
        <br />
        <a
          className="hover:link text-md text-primary font-bold"
          href="https://www.iii.org/fact-statistic/facts-statistics-wildfires"
          target="_blank"
          rel="noreferrer"
        >
          Link to US Wildfire Statistics
        </a>
        <br />
        <article className="article-content">
          <p>Methods to prevent wildfires:</p>
          <ul className="list-disc list-inside mx-4">
            <li>Prescribed burns.</li>
            <li>
              Keeping invasive species from overtaking native populations.
            </li>
            <li>
              Educating the general public about properly extinguishing any
              ignition sources.
            </li>
          </ul>
          <br />
          <p>
            It is important to realize that regular, small wildfires are healthy
            for forests and grasslands under normal conditions.
          </p>
          <br />
          <h3 className="text-lg font-bold text-center text-primary m-3">
            Preparing for a Wildfire
          </h3>
          <ol className="space-y-1 list-decimal list-inside">
            <li>
              Have a plan. This should be both an evacuation plan, and long-term
              maintenance of your home to fortify it against a wildfire as best
              as you can. Make sure you have a communications plan with family
              members to stay in touch if you get separated.
              <br />
              <a
                className="hover:link font-bold"
                href="https://www.readyforwildfire.org/prepare-for-wildfire/go-evacuation-guide/pre-evacuation-preparation-steps/"
                target="_blank"
                rel="noreferrer"
              >
                Pre-evacuation steps
              </a>
              <br />
              Ensure you have multiple evacuation routes and places to meet up
              well outside a wildfire zone. Plan on not returning home for a few
              days, or up to a week.
            </li>
            <li>
              Fortifying your home/creating defensible space. This involves
              removing flammable material from near your home in a few different
              zones and severities. The goal is to deprive a wildfire of
              anything that could burn near your home. Here is a diagram
              outlining how to do this -{" "}
              <a
                className="hover:link font-bold"
                href="https://csfs.colostate.edu/wildfire-mitigation/protect-your-home-property-from-wildfire/"
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </li>
            <li>
              Obtaining emergency supplies: If you're in an area with wildfires,
              your bug-out bags should be structured around evacuation. Ensure
              you have backups of essential documents and have originals in a
              fireproof safe. Cash for purchases, extra medications, pet
              supplies, and any other necessities should be ready to go at a
              moment's notice.
              <br />
              Supplies that are wildfire-specific may include -
              <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                <li>
                  Face masks or respirators for heavy smoke, such as N95 masks.
                </li>
                <li>
                  A map marked with at least two evacuation routes including
                  meet-up locations if separated.
                </li>
                <li>
                  Prescriptions or special medications such as high cholesterol
                  medication, anti-seizure prescriptions, insulin, or thyroid
                  medication.
                </li>
                <li>Change of clothing.</li>
                <li>Extra eyeglasses or contact lenses.</li>
                <li>
                  An extra set of car keys, credit cards, cash, or traveler's
                  checks.
                </li>
                <li>First aid kit including cold compresses and burn cream.</li>
                <li>Flashlight.</li>
                <li>Battery-powered radio and extra batteries.</li>
                <li>Sanitation supplies.</li>
                <li>
                  Copies of important documents (house deeds, photos, birth
                  certificates, passports, etc.).
                </li>
              </ul>
            </li>
            <li>
              Listen to evacuation orders. You can't fight a wildfire. These
              disasters can move unsettlingly fast and outpace even someone
              running. If an evacuation order is given, do not delay and get
              out!
            </li>
          </ol>
          <br />
          <a
            className="hover:link font-bold"
            href="https://www.ready.gov/wildfires"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            https://www.ready.gov/wildfires
          </a>
        </article>
      </div>
    </>
  );
}
