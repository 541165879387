import React from "react";

export default function Duration() {
  return (
    <div className="page-content">
      <h1>
        Duration Health
      </h1>
      <article className="article-content">
        <p>
          Founded in 2019, Duration Health serves as an incredible, customizable
          resource to obtain essential medications prior to a disaster or
          backcountry trip. Utilizing telehealth resources with licensed
          practitioners, Duration Health lets you customize your kit far outside
          the standard antibiotics both they and other companies typically
          offer. If you have special needs such as an EpiPen, anti-nausea
          medication, or many other options, this kit is able to be
          custom-tailored for your individual requirements.
        </p>
        <br />
        <p>
          Unlike other options, there's a video telehealth visit in addition to
          the questionnaire, where you discuss with a practitioner your needs.
          They suggest various items that could be useful for your particular
          situation, and lets you ask any specific questions to help
          custom-tailor your kit to your needs. This allows a degree of
          customization unmatched by any other company, and a kit can be
          tailor-made for a specific trip or location.
        </p>
        <br />
        <p>
          Another positive trait of this company is that you can order a kit for
          separate occasions. For example, one for home, for your RV or boat,
          for a car, and so forth. While the base cost of the kit is higher than
          other options (starting at around $299 and increasing with each add-on
          medication,) the intense customization allows a far more customizable
          kit than other options. This includes add-ons, kits tailored for
          specific regions, expeditions, and more.
        </p>
        <br />
        <p>
          As with other products I support on this site, I have Duration
          Health's kit, and it's an incredible and comprehensive product to say
          the least. With ad-ons, it can be expensive, but certainly is worth it
          if you have needs that require additional medications.
        </p>
        <br />
        <p>
          I've partnered with Duration Health to both support the work they're
          doing, as well as get a discount code, as the kits can be pricey for
          those starting out. The code is optional, but it saves you some money
          and helps support the site and work that I do.
        </p>
        <br />
        <p className="mb-5 font-bold">
          Enter “PREPARE1” at checkout to get $50 off.
        </p>
        <br />
        <a
          className="hover:link font-bold"
          href="https://durationhealth.com/"
          rel="noreferrer"
          target="_blank"
        >
          Link to Duration Site
        </a>
      </article>
    </div>
  );
}
