import React from "react";

export default function EMP() {
  return (
    <>
      <div className="page-content">
        <h1>
          EMP/CME
        </h1>
        <article className="article-content">
          <h2>
            What is an EMP?
          </h2>
          <p>
            An EMP is an abbreviation of ElectroMagnetic Pulse. This phenomenon
            occurs naturally (such as a lightning strike) but in the context of
            emergency preparedness and concerns, it is usually referring to a
            byproduct of a nuclear explosion.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Nuclear Explosion"
            src="https://images.pexels.com/photos/73909/nuclear-weapons-test-nuclear-weapon-weapons-test-explosion-73909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />
          <br />
          <p>
            This is known as an H-EMP, as is written about in the fictional
            novel, <i>One Second After. </i>
            Now disbanded, the EMP Commission was formed within the government
            during 2001 and tasked with exploring this potential disaster
            further. The report, in short, stated that the majority of the
            population would be dead within a year due to cascading effects of
            infrastructure failure. It is worth noting this same result can be
            achieved without using nuclear methods in the form of a successful
            cyber attack, which, in my opinion, is infinitely more likely.
            <br />
            <br />
            In short, detonating a nuclear device in the atmosphere would cause
            an incredible surge of energy that would damage or destroy most
            electronic items connected to the grid, or otherwise unshielded.
            <br />
            <br />
            An EMP fits into what is called a low-likelihood, high-causality
            event, such as a large earthquake, for this reason. Extreme low
            likelihood of occurring, but if it <i>does</i>, the results are
            catastrophic.
            <br />
            <br />
            Now on to more about an EMP. Due to how the radiation generated from
            the device interacts and propagates within the atmosphere (it's
            called the Compton Effect) a successful HEMP would essentially fry
            sensitive electronics within its range in the area beneath the
            detonation (hundreds of miles/kilometers depending on altitude.) For
            a HEMP, it is irrelevant if the device is plugged in. If the device
            contains microprocessors, it is at risk, turned off or not.
            Computers, cell phones, GPS, etc.
          </p>
          <h2>
            Who would an EMP/CME affect?
          </h2>
          <p>
            Since an H-EMP is a localized phenomenon, the effects would be
            limited to line of sight. That may be a small area, or potentially a
            continent-sized zone. A Coronal Mass Ejection would be a worldwide
            phenomenon.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Solar EMP"
            src="https://images.pexels.com/photos/39561/solar-flare-sun-eruption-energy-39561.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <h2>
            How to Prepare for an EMP?
          </h2>
          <p>
            The primary (reasonable) way to protect against this is a Faraday
            cage. (Such as a metal trash can with a tightly-sealing lid, or
            multiple layers of tin foil that aren't contacting the device being
            protected) This provides a barrier that outright blocks the
            frequencies from interacting with the devices placed inside (as long
            as they're not touching the walls of the cage.) It is disputed if
            grounding a Faraday cage is necessary, and there are vehement
            arguments on both sides. Creating a 'nested' Faraday cage is also
            possible- a Faraday cage within a Faraday cage (tin foil-wrapped
            boxes within a metal trash can, for example)
            <br />
            <br />
            <a
              className="font-bold hover:link"
              href="https://modernsurvivalblog.com/emp/microwave-oven-used-as-a-faraday-cage/"
              target="_blank"
              rel="noreferrer"
            >
              A microwave is not an effective, wide-spectrum Faraday cage.{" "}
            </a>
            In practice, it is a specific-frequency Faraday cage (hence how it
            heats the food and not the face of someone watching it,) but it
            would not protect against the wide spectrum of waves generated by an
            EMP. A good test for a Faraday cage is to get a good radio, tune it
            to the lowest AM station available, and place it inside the cage. If
            you don't hear anything, then it is blocking the frequencies. Repeat
            this test for low FM, and then high AM/FM stations.
            <br />
            <br />
            A common question is regarding cars during an EMP. The EMP
            Commission did studies on this, and it is extremely likely a car
            would be rendered useless. The below links explain why the cars in
            the report started back up. Simply put, the Commission couldn't
            afford to destroy them, and therefore limited the tests. Their
            personal accounts indicate cars could, and would be absolutely
            destroyed.
            <br />
            <br />
            From a now-offline interview, Dr. Peter Vincent Pry (head of the EMP
            Commission) stated that the cars utilized in the tests were borrowed
            and could NOT be fully tested. As soon as something was starting to
            fail (at low levels), they stopped. They couldn't afford to buy 25
            cars/trucks to see them tested to the full EMP levels.
          </p>
          <br />
          <p>A summed up quote is -</p>
          <br />
          <div className="flex justify-center">
            <blockquote className="mx-5 px-5 border-l-2 border-zinc-900 dark:border-zinc-400">
              <i>
                <p>
                  "Since we saw cars failing at surprisingly low levels of an
                  EMP, the Commission knew that we could have destroyed many
                  cars, but couldn't afford it."
                </p>
              </i>
            </blockquote>
          </div>
          <br />
          <p>
            That is from the EMP Commission, and I am inclined to take their
            word over claims that cars 'might' be grounded, etc. Cars nowadays
            have much more integrated electronics than 10+ years ago. Vehicles
            that are pre-electronic fuel injection (1980's), are less likely to
            be affected by an EMP, and should be considered if that is a
            concern.
            <br />
            <br />
            Dr. Pry was gracious enough to answer a follow-up question
            concerning cars. He confirmed that the cars would likely be
            damaged/destroyed, but pointed out that it's somewhat moot. A
            successful EMP would render roads utterly impassable due to
            accidents and traffic due to the EMP.
          </p>
          <h2>
            What is a CME?
          </h2>
          <p>
            A CME is a burst of radiation from the sun, simply put. In this
            case, it would largely affect only devices plugged into the
            electronic grid. That, or which contain a long antenna. No Faraday
            cage is necessary in this case and will largely affect power
            stations, transformers, etc. There will likely be a warning prior,
            as such energy takes time to reach Earth (hours.)
            <br />
            <br />
            In either case, if large transformers are destroyed the power
            infrastructure of the U.S is likely to be down for months if not
            over a year. This is due to large transformers not being stored in
            backup locations (due to cost, size, and specific design,) as well
            as the original ways that the devices were transported no longer
            exist in some cases (rail lines.) This is elaborated in Ted Koppel's
            book,{" "}
            <span>
              <a
                className="hover:link font-bold"
                href="https://www.amazon.com/Lights-Out-Cyberattack-Unprepared-Surviving/dp/0553419986?_encoding=UTF8&qid=1638662316&sr=8-1&linkCode=sl1&tag=pickupapiece-20&linkId=387bc51ba1033d1872509bb934b19357&language=en_US&ref_=as_li_ss_tl"
                target="_blank"
                rel="noreferrer"
              >
                Lights Out
              </a>
            </span>
            , which delves into the preparation against a Cyber-attack,
            something that would achieve a similar, long-term result as an EMP
            without the nuclear device. That is not the focus of this page
            however.
          </p>
          <br />
          <h2>
            How to build a cheap but decently effective Faraday cage?
          </h2>
          <br />
          <p className="mb-5">
            Metal trash can with a tight lid + sealing the seams with metallic
            tape/solder is what I have. Total cost. like 30ish bucks. Insulate
            items inside wrapped in foil to created a 'nested' Faraday cage
            (Faraday cage inside a Faraday cage.)
            <br />
            <br />
            I saw a comment on the link below that someone bought a 30 gallon
            can, sprayed foam inside it, then put a 20 gallon inside and sealed
            both lids. I will personally upgrade to that since it's such a good
            idea.
            <br />
            <br />
            Grounding isn't necessary and may conduct the waves into the cage,
            from what I understand. Cheap, easy, and low stress. An EMP is an
            extremely low-likelihood, but high impact event. While not worth
            discounting, it's not worth breaking the bank over preparing for.
            (Versus more common, likely disasters such as job loss, civil
            unrest, etc.)
            <br />
            <br />
            <a
              className="hover:link font-bold"
              href="https://amrron.com/2015/03/24/emp-trash-can-faraday-cage-testing-in-lab/"
              target="_blank"
              rel="noreferrer"
            >
              Video of a rough test
            </a>
            <br />
            <br />
            Sources:
            <br />
            <a
              className="hover:link"
              href="http://www.empcommission.org/reports.php"
              target="_blank"
              rel="noreferrer"
            >
              <i>EMP Commission official reports</i>
            </a>
            <br />
            <br />
            Additional resources:
            <br />
            <a
              className="hover:link"
              href="https://www.everycrsreport.com/reports/RL32544.html
              https://interestingengineering.com/what-are-emps-and-how-are-they-used-in-warfare"
              target="_blank"
              rel="noreferrer"
            >
              <i>What is an EMP</i>
            </a>
            <br />
            <a
              className="hover:link"
              href="https://www.govtech.com/em/emergency-blogs/disaster-zone/thedifferencebetweenanempandacme.html"
              target="_blank"
              rel="noreferrer"
            >
              <i>EMP vs CME</i>
            </a>
          </p>
        </article>
      </div>
    </>
  );
}
