import React from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";

export default function Landing() {
  return (
    <>
      <Hero />
      <div className="my-20">
        <div className="grid justify-items-center text-left">
          <h1 className="text-3xl font-bold text-center text-primary m-3">
            A beginning and foreword.
          </h1>
          <article className="article-content py-6">
            <p>
              The first line of defense against a crisis, big or small, is an
              individual, family, and community. Pick Up A Piece serves as a
              uniting factor in preparing and responding to a crisis where
              political view, race, religion, or other attributes are not
              barriers. It aims to point people in the right direction, to offer
              both guides for preparing for a crisis as well as direct links to
              products that are well-researched and widely relied upon.
            </p>
            <br />
            <p>When disaster strikes, somebody's gotta be there.</p>
            <br />
            <p>
              This is Pick Up A Piece, putting the world back together when it
              all falls apart.
            </p>
            <br />
          </article>
          <Link reloadDocument to="/prepare">
            <button className="btn hover:bg-primary hover:text-base-100 font-bold">
              Click here to get started
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
