import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header, Footer } from "./components";

import * as Pages from "./pages";
import * as P4C from "./components/p4c";
import * as Products from "./components/products";

export default function App() {
  return (
    <div className="App flex flex-col min-h-screen bg-base-100">
      <Router>
        <Header />
        <Routes>
          <Route index path="/" element={<Pages.Landing />} />
          <Route path="about" element={<Pages.About />} />
          <Route path="products" element={<Pages.Products />}>
            <Route index path="" element={<Products.Landing />} />
            <Route path="first-aid" element={<Products.FirstAid />} />
            <Route path="food" element={<Products.Food />} />
            <Route path="ppe" element={<Products.PPE />} />
            <Route path="water" element={<Products.Water />} />
            <Route path="*" element={<Pages.NoMatch />} />
          </Route>
          <Route path="prepare" element={<Pages.Prepare />}>
            <Route index path="" element={<P4C.Landing />} />
            <Route path="become-a-mitigation-agent" element={<P4C.Mitigation />} />
            <Route path="become-a-resiliency-agent" element={<P4C.Resiliency />} />
            <Route path="food" element={<P4C.Food />} />
            <Route path="water" element={<P4C.Water />} />
            <Route path="communications" element={<P4C.Communications />} />
            <Route path="first-aid" element={<P4C.FirstAid />} />
            <Route path="first-aid/jase-medical" element={<P4C.JASE />} />
            <Route path="first-aid/contingency-medical" element={<P4C.Contingency />}/>
            <Route path="first-aid/duration-medical" element={<P4C.Duration />}/>
            <Route path="self-defense" element={<P4C.SelfDefense />} />
            <Route path="emp" element={<P4C.EMP />} />
            <Route path="flood" element={<P4C.Flood />} />
            <Route path="hurricane" element={<P4C.Hurricane />} />
            <Route path="severe-weather" element={<P4C.SevereWeather />} />
            <Route path="wildfire" element={<P4C.Wildfire />} />
            <Route path="nuclear-disaster" element={<P4C.NuclearDisaster />} />
            <Route path="*" element={<Pages.NoMatch />} />
          </Route>
          <Route path="general-news" element={<Pages.General />} />
          <Route path="privacy-policy" element={<Pages.PrivacyPolicy />} />
          <Route path="contact" element={<Pages.Contact />} />
          <Route path="*" element={<Pages.NoMatch />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}