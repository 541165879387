import React from "react";

export default function SevereWeather() {
  return (
    <>
      <div className="page-content">
        <h1>
          Severe Weather
        </h1>
        <article className="article-content">
          <p>
            For the purpose of this article, the focus will be on the United
            States. Due to its size topography, and varied climates, the U.S.
            has some of the world's move extreme and diverse weather.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Lightning"
            src="https://images.pexels.com/photos/99577/barn-lightning-bolt-storm-99577.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />
          <br />
          <p>
            First off, severe weather needs to be defined.
            <br />
            <br />
            Severe weather is any meteorological event that's out of the
            ordinary and presents a danger to life. A typical event such as a
            thunderstorm can strengthen to become a severe weather event
            depending on the severity.
          </p>
          <br />
          <p>
            Disasters that are classified as severe weather include tornadoes
            and hurricanes. Events such as a wildfire can be the result of
            severe weather but aren't classified as such. 'Severe Weather'
            encompasses events that can both be disasters, or just more intense
            and extreme versions of day-to-day weather such as hot or cold
            temperature ranges.
          </p>
          {/* Insert a severe thunderstorm aftermath picture here */}
          <br />
          <p>
            Within the U.S., every state is either at risk of or regularly
            experiences a form of extreme weather. This may be due to
            temperature swings or more localized events. One of the more regular
            risks of severe weather is either a short-term or medium-term power
            outage.
          </p>
          <br />
          <p>
            The preparations for this event are fairly simple if your location
            isn't at risk for a longer-term disaster. Maintaining a grab-and-go
            bag (bug-out bag, go-bag, etc.) with 72 hours of supplies will get
            you through the majority of severe weather events. Additionally, a
            gas generator or solar array could be useful to charge both personal
            items and run larger appliances if the power outage is longer than
            six hours. Being self-sufficient for three days will get you through
            the majority of severe weather events.
          </p>
          <br />
          <p>
            That said, FEMA recommends individuals and families should store 2
            weeks of supplies. I'd consider that the bare-bones level of
            preparedness especially if you live in an area with the chance of
            natural disasters such as hurricanes, ice storms, tornadoes, or any
            other event that can affect a large area over a long period of time.
          </p>
          <br />
          <p>Additional supplies to consider for severe weather events:</p>
          <ul className="space-y-1 list-disc list-inside mx-4">
            <li>Essential medications</li>
            <li>Change of clothing</li>
            <li>
              Paper map with evacuation routes/a family communications plan
            </li>
            <li>Spare eyeglasses/contacts</li>
            <li>Extra set of keys</li>
            <li>Flashlight with batteries</li>
            <li>Backup power batteries (Bluetti, Goal Zero)</li>
            <li>Battery-powered radio</li>
            <li>Copies of essential documents</li>
          </ul>
          <br />
          <p>
            During severe weather, whatever it may be, listen to official news
            weather channels. This allows you to hear any alerts for
            location-specific disasters such as tornadoes. Don't plan on doing
            last-minute shopping unless absolutely necessary. There's a reason
            stores are bare before a disaster. Everyone else has the same idea!
          </p>
          <br />
          <p>
            For options to receive severe weather alerts, the following site
            provides ways of being connected.{" "}
            <a
              className="hover:link font-bold"
              href="https://www.weather.gov/wrn/wea"
              target="_blank"
              rel="noreferrer"
            >
              https://www.weather.gov/wrn/wea
            </a>
          </p>
          <br />
          <p>
            Weather alerts are also broadcast on weather apps and websites.
            There are also specific weather radios tuned to broadcast watches or
            warnings in your area, programmable to each state or county.
          </p>
          <br />
        </article>
        <img
          className="my-5"
          src={require("../../../assets/images/receiving_weather_alerts.png")}
          alt="Receiving Weather Alerts Graphic"
        />
        <h2>
          Tornadoes
        </h2>
        <article className="article-content">
          <p>
            Tornadoes are severe weather events that almost always are created
            from a severe thunderstorm. A large, quickly-rotating column of air
            causes extremely high winds and flying debris. Rated on the EF scale
            from 0 (minor) to 5 (catastrophic,) these events can flatten almost
            everything in their path.
          </p>
          <br />
          <a
            className="hover:link font-bold"
            href="https://www.weather.gov/oun/efscale"
            target="_blank"
            rel="noreferrer"
          >
            EF Scale
          </a>
          <br />
          <p>
            If you live in an area prone to tornadoes (such as 'Tornado Alley'
            in the U.S.), having a safe room or basement is highly recommended.
            Sheltering underground is the safest option for this disaster or
            evacuating to a shelter if you have warning.
          </p>
          <br />
          <p>
            However, these events can move quickly and unpredictably, so staying
            tuned into a local station to track their movements is also
            essential. Having a 72-hour kit is imperative for this disaster, as
            you literally may only have minutes to get somewhere safe. Ensure
            you are signed up for local weather alerts and have ways to monitor
            if a warning is given.
          </p>
        </article>
        <h2>
          Extreme Hot/Cold Events
        </h2>
        <article className="article-content">
          <p>
            To anyone caught unprepared, extreme temperature fluctuations can be
            extremely dangerous. For both heat waves and cold spells, the power
            grid can be placed under excessive stress causing an outage. The
            lack of power can then cause secondary disasters due to utilities
            being unavailable.
          </p>
          <br />
          <p>
            In both cases, staying indoors and making your home as comfortable
            as possible is the safest option. For extreme heat, putting tin foil
            on the windows or investing in blackout curtains can reduce the
            ambient temperature indoors.
          </p>
          <br />
          <p>Supplies to consider for a heatwave -</p>
          <ul className="space-y-1 list-disc list-inside mx-4">
            <li>Cold packs</li>
            <li>Loose fitting clothing</li>
            <li>Extra water</li>
            <li>Sunscreen</li>
            <li>Backup power for grid failure</li>
          </ul>
          <br />
          <p>
            Limit outdoor activity during heat waves, and drink plenty of water.
            Having first aid training is also a plus, as you should watch out
            for the signs of heat exhaustion/heat stroke.
          </p>
        </article>
        <img
          className="my-5"
          src={require("../../../assets/images/Heat_Related_Illness.jpg")}
          alt="Receiving Weather Alerts Graphic"
        />
        <br />
        <article className="article-content">
          <p>
            For extreme cold, having a supply of thick, wool blankets and spare
            undergarments is a prudent step to take.
          </p>
          <br />
          <p>Additional supplies to consider include -</p>
          <ul className="space-y-1 list-disc list-inside mx-4">
            <li>Hand warmers</li>
            <li>Hot drinks</li>
            <li>Cold-weather clothing and undergarments</li>
            <li>Backup power for grid failure</li>
          </ul>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Blizzard"
            src="https://images.pexels.com/photos/714482/pexels-photo-714482.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <p className="mb-3">
            In both cases, having supplies in your car is also an essential
            preparation. Spare water (in pouches to handle the temperature
            swings,) along with heating/cooling items should be part of your car
            kit if you're in an area prone to such events.
          </p>
        </article>
      </div>
    </>
  );
}
