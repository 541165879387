import { useState, useEffect } from "react";

export default function useDarkMode() {
  if (!localStorage.theme || localStorage.theme !== "dark") {
    localStorage.setItem("theme", "light")
  };

  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === "dark" ? "light" : "dark";

  useEffect(() => {
    const root = window.document.documentElement;

    root.removeAttribute("data-theme");
    root.setAttribute("data-theme", theme);

    theme === "dark"
      ? root.classList.add("dark")
      : root.classList.remove("dark");

    localStorage.setItem("theme", theme);
  }, [theme, colorTheme]);

  return [colorTheme, setTheme];
}
