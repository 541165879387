import React from "react";

export default function Contact() {
  return (
    <div className="min-h-screen">
      <div className="page-content">
        <h1>
          Contact Info
        </h1>
        <article className="article-content">
          <p>
            If there's a question or concern you have, please feel free to send
            an email/message and I'll try my hardest to get back to you. I have
            been connecting with individuals and helping them prepare as best I
            can for years, so I'm happy to help. <br />
            <br />
            I'm active on{" "}
            <a
              className="hover:link font-bold"
              href="https://www.reddit.com/r/preppers/hot/"
              target="_blank"
              rel="noreferrer"
            >
              r/preppers
            </a>
            , so feel free to pop in! <br />
            You can check out my reddit profile{" "}
            <a
              className="hover:link font-bold"
              href=" https://www.reddit.com/user/TheRealBunkerJohn"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            !<br /> <br />
            For Organization-related glitches, suggestions, feedback, and other
            matters, email{" "}
            <a
              className="hover:link font-bold"
              href="mailto:pickupapiecehub@gmail.com"
            >
              {" "}
              pickupapiecehub@gmail.com <br /> <br />
            </a>
            For business inquiries, email{" "}
            <a
              className="hover:link font-bold"
              href="mailto:bunkerjohn52@gmail.com"
            >
              bunkerjohn52@gmail.com
            </a>{" "}
            <br /> <br />
            Please excuse any delay in responses; I'll get to them as quickly as
            I can.
          </p>
        </article>
      </div>
    </div>
  );
}
