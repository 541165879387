import React from "react";

export default function Flood() {
  return (
    <>
      <div className="page-content">
        <h1>
          Food Storage
        </h1>
        <article className="article-content">
          <p>
            FEMA recommends having a two-week supply of food. -{" "}
            <a
              className="hover:link font-bold"
              href="https://www.fema.gov/pdf/library/f&web.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
            <br />
            <br />
            This can thankfully be accomplished without much hassle or available
            funds. Many individuals desire to have a greater supply of food;
            that is where the long-term food storage comes in.
          </p>
          <br />
          <p>
            To begin, there are two primary ways to build your food storage. A
            rotating pantry, and purchasing long-term items in a 'buy and
            forget' sort of manner (which can also work in the short-term, if
            necessary for your localized crisis.) In either case, food should be
            kept cool and out of the sun. High temperature is the enemy of food
            storage.
          </p>
          <br />
          <p>
            A rotating pantry is much more appropriate for a two-week supply of
            food. It's a bit more involved but much easier to do with limited
            funds. Simply purchase an extra item whenever you go shopping. If
            you usually buy a single can of chicken, buy two.
          </p>
          <br />
          <p>
            In doing this over time, you can then use the oldest of the items
            and then replace it with a new one; hence 'rotating pantry'. You
            have a constant stock of food at home that you utilize daily and is
            set for any disruption. This varies from person to person. For the
            long-term food storage options, I have suggestions of products I've
            used/would recommend in the{" "}
            <a
              className="hover:link font-bold"
              href="/products"
              target="_blank"
              rel="noreferrer"
            >
              Products
            </a>{" "}
            tab.
          </p>
          <h2>
            Short Term Food Storage
          </h2>
          <p>
            These items require little to no preparation. These include energy
            bars (packaged for long shelf life), MRE’s (Meals Ready to Eat),
            Peanut Butter, and any other food item that can be consumed with
            little to no preparation. This area of your food storage is ideal
            for a crisis that, by either the scope of the crisis or level of
            destruction, doesn’t allow much time or means for preparing food.
            Shelf life: Ranging from 1-2 years up to 5-10+
          </p>
          <br />
          <br />
          <p>
            If you have the time and ability, buying bulk materials and sealing
            them in mylar is a fun skill to perfect! It can definitely be
            cheaper but requires a bit of know-how and trial and error. -{" "}
            <a
              className="hover:link font-bold"
              href="https://theprovidentprepper.org/how-to-package-dry-foods-in-mylar-bags-for-long-term-storage/"
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
          </p>
          <br />
          <p>
            Side note: Vacuum sealing foods is not an effective long-term
            storage option. For the short term in the freezer or such, it's
            fine. But the seal is not meant to be permanent and will lose
            integrity over time.
            <br />
            <br />
            <br />
            </p>
          <h2>
            Medium Term Food Storage
          </h2>
          <p>
            This is where dehydrated or freeze-dried foods would fall. Brands
            such as Mountain House, for example, offer a meal-in-a-pouch
            products (or #10 cans.) These items require water to prepare and can
            be considered short-term items if water is available. Food products
            such as instant oatmeal/rice, or other items which require only
            minimal preparation would fall in this category. Shelf life: 10
            years+
          </p>
          <h2>
            Long Term Food Storage
          </h2>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Long Term Food Storage"
            src="https://images.pexels.com/photos/6994944/pexels-photo-6994944.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <p>
            This is where supplies purchased in bulk would fall. Raw materials
            such as wheat that require processing, rice that needs to be cooked,
            and other foods including seasoning products. These products include
            5-gallon buckets with raw goods packaged in sealed, mylar bags.
            Shelf life: 30+ years.
            <br />
            <br />
            This requires both more funds and/or a bit of practice if doing it
            yourself. I personally break long-term food into three categories,
            each requiring a different level of food preparation before
            consuming. In an extended crisis, mixing and matching the categories
            is ideal to break up food monotony and nutritional content.
          </p>
          <br />
          <p>
          Additional materials on food storage:
          <br />
            <a
              className="hover:link"
              href="https://www.shtfblog.com/wp-content/uploads/2020/04/LDSPrep-V8.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <i>LDS Preparedness Manual</i>
            </a>
            <br />
            <a
              className="hover:link"
              href="https://www.ready.gov/food "
              target="_blank"
              rel="noreferrer"
            >
              <i>https://www.ready.gov/food</i>
            </a>
          </p>
        </article>
      </div>
    </>
  );
}
