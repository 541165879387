import React from "react";
import { Link } from "react-router-dom";

export function ProductsL() {
  return (
    <>
      <ul className="menu menu-horizontal p-0 animate-appear">
        <li>
          <Link to="/products">
            <button type="button">Products</button>
          </Link>
        </li>
        <li>
          <Link to="/products/food">
            <button className="rounded-lg" type="button">
              Food
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/water">
            <button className="rounded-lg" type="button">
              Water
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/ppe">
            <button className="rounded-lg" type="button">
              PPE
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/first-aid">
            <button className="rounded-lg" type="button">
              First Aid
            </button>
          </Link>
        </li>
      </ul>
    </>
  );
}

export function ProductsS() {
  return (
    <>
      <ul
        tabIndex={0}
        className="menu menu-compact dropdown-content mt-3 p-2 shadow dark:bg-secondary bg-secondary rounded-box w-52"
      >
        <li>
          <Link to="/products" className="btn btn-ghost normal-case">
            <button className="rounded-lg" type="button">
              Products
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/food">
            <button className="rounded-lg" type="button">
              Food
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/water">
            <button className="rounded-lg" type="button">
              Water
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/ppe">
            <button className="rounded-lg" type="button">
              PPE
            </button>
          </Link>
        </li>
        <li>
          <Link to="/products/first-aid">
            <button className="rounded-lg" type="button">
              First Aid
            </button>
          </Link>
        </li>
      </ul>
    </>
  );
}
