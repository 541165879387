import React from "react";

export default function SelfDefense() {
  return (
    <>
      <div className="page-content">
        <h1>
          Self Defense
        </h1>
        <article className="article-content">
          <p className="mt-3">
            Self-defense is an essential part of self-reliance. It's the act and
            mindset of taking control of your own safety, of recognizing that
            should an unfortunate circumstance arrive, you can have the power to
            keep yourself and your family safe.
          </p>
          <br />
          <p>
            This can come in many forms. If you're in the U.S., a firearm is the
            best option for self-defense. However, owning a firearm is an
            incredible responsibility. It takes introspection and some genuine
            self-reflection if you are willing and able to use it in a
            self-defense scenario. I'm certainly not the one to tell you what is
            right in that respect, and nor should anyone else.
          </p>
          <br />
          <p>
            If you choose to purchase a firearm for home defense, I'd recommend
            either an AR-15 or Shotgun. Bullets penetrate matter. Interestingly,
            the round used by an AR-15 goes through fewer walls and drywall than
            a shotgun or pistol. However, the best weapon to use for
            self-defense is the one you're most comfortable with, period.
          </p>
          <br />
          <p>
            If you choose to own a firearm, I would urge you to obtain training.
            There are multiple options to do so, but I recommend the NRA's
            education courses.
          </p>
          <br />
          <p>
            The education arm of the NRA is almost entirely separate from the
            political arm (something I'm personally grateful for.) While it'll
            vary from instructor to instructor in that regard, the content of a
            Basic Rifle/Pistol/Shotgun course is a fantastic way to familiarize
            yourself with different types of firearms and start on that journey.
          </p>
          <br />
          <p>
            Outside of firearms, (and in addition to,) there are multiple
            options. Martial arts are a fantastic way to improve both fitness
            and mastery of one's self. For day-to-day life, pepper spray and
            other non-lethal options are ideal to have on hand if other options
            aren't preferred or available.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Martial Arts"
            src="https://images.pexels.com/photos/8612653/pexels-photo-8612653.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <p>
            Personally, I recommend a layered approach. At the very least,
            having a non-lethal option at hand is what I see as the bare-bones
            level of options. That means pepper spray/gel, or knowing martial
            arts.
          </p>
          <br />
          <p className="mb-5">
            While I have some products recommended in the Store tab, I'd urge
            you to consider what best fits you personally in terms of
            self-defense.
          </p>
        </article>
      </div>
    </>
  );
}
