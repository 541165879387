import React from "react";
import { Link } from "react-router-dom";

export function PrepareL() {
  return (
    <>
      <ul className="menu menu-horizontal p-0 animate-appear">
        <li tabIndex={0}>
          <button>
            <Link to="/prepare">Prepare for a Crisis</Link>
          </button>
        </li>
        <li tabIndex={0}>
          <button className="justify-between rounded-t-lg">
            Agents
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </button>
          <ul className="p-2 dark:bg-secondary bg-secondary rounded-b-lg animate-altappear">
            <li>
              <Link to="/prepare/become-a-mitigation-agent">
                <button className="rounded-lg" type="button">
                  Become a Mitigation Agent
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/become-a-resiliency-agent">
                <button className="rounded-lg" type="button">
                  Become a Resiliency Agent
                </button>
              </Link>
            </li>
          </ul>
        </li>
        <li tabIndex={0}>
          <button className="justify-between rounded-t-lg">
            Foundations
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </button>
          <ul className="p-2 dark:bg-secondary bg-secondary rounded-b-lg animate-altappear">
            <li>
              <Link to="/prepare/food">
                <button className="rounded-lg" type="button">
                  Food
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/water">
                <button className="rounded-lg" type="button">
                  Water
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/communications">
                <button className="rounded-lg" type="button">
                  Communications
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/first-aid">
                <button className="rounded-lg" type="button">
                  First Aid
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/self-defense">
                <button className="rounded-lg" type="button">
                  Self Defense
                </button>
              </Link>
            </li>
          </ul>
        </li>
        <li className="dropdown-end">
          <button className="justify-between rounded-t-lg">
            Disasters
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </button>
          <ul className="dropdown-content dark:bg-secondary bg-secondary rounded-box animate-altappear">
            <li>
              <Link to="/prepare/emp">
                <button className="rounded-lg" type="button">
                  EMP/CME
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/flood">
                <button className="rounded-lg" type="button">
                  Flood
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/hurricane">
                <button className="rounded-lg" type="button">
                  Hurricane
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/severe-weather">
                <button className="rounded-lg" type="button">
                  Severe Weather
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/wildfire">
                <button className="rounded-lg" type="button">
                  Wildfire
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/nuclear-disaster">
                <button className="rounded-lg" type="button">
                  Nuclear Disaster
                </button>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
}

export function PrepareS() {
  return (
    <>
      <ul
        tabIndex={0}
        className="menu menu-compact dropdown-content mt-3 p-2 shadow dark:bg-secondary bg-secondary rounded-box w-52"
      >
        <li tabIndex={0}>
          <Link to="/prepare" className="btn btn-ghost normal-case">
            <button className="rounded-lg" type="button">
              Prepare for a Crisis
            </button>
          </Link>
        </li>
        <li tabIndex={0}>
          <button className="justify-between">
            Agents
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </button>
          <ul className="p-2 dark:bg-secondary bg-secondary rounded-b-lg animate-altappear">
            <li>
              <Link to="/prepare/become-a-mitigation-agent">
                <button className="rounded-lg" type="button">
                  Become a<br />
                  Mitigation Agent
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/become-a-resiliency-agent">
                <button className="rounded-lg" type="button">
                  Become a<br />
                  Resiliency Agent
                </button>
              </Link>
            </li>
          </ul>
        </li>
        <li tabIndex={0}>
          <button className="justify-between">
            Foundations
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </button>
          <ul className="p-2 dark:bg-secondary bg-secondary rounded-b-lg animate-altappear">
            <li>
              <Link to="/prepare/food">
                <button className="rounded-lg" type="button">
                  Food
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/water">
                <button className="rounded-lg" type="button">
                  Water
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/communications">
                <button className="rounded-lg" type="button">
                  Communications
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/first-aid">
                <button className="rounded-lg" type="button">
                  First Aid
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/self-defense">
                <button className="rounded-lg" type="button">
                  Self Defense
                </button>
              </Link>
            </li>
          </ul>
        </li>
        <li tabIndex={0}>
          <button className="justify-between">
            Disasters
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </button>
          <ul className="p-2 dark:bg-secondary bg-secondary rounded-b-lg animate-altappear">
            <li>
              <Link to="/prepare/emp">
                <button className="rounded-lg" type="button">
                  EMP/CME
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/flood">
                <button className="rounded-lg" type="button">
                  Flood
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/hurricane">
                <button className="rounded-lg" type="button">
                  Hurricane
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/severe-weather">
                <button className="rounded-lg" type="button">
                  Severe Weather
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/wildfire">
                <button className="rounded-lg" type="button">
                  Wildfire
                </button>
              </Link>
            </li>
            <li>
              <Link to="/prepare/nuclear-disaster">
                <button className="rounded-lg" type="button">
                  Nuclear Disaster
                </button>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
}
