import React from "react";

export default function Resiliency() {
  return (
    <>
      <div className="page-content">
        <h1>
          Become a Resiliency Agent/Resilient Family
        </h1>
        <article className="article-content">
          <p>
            Becoming a Resiliency Agent/Resilient Family is similar to being a
            Mitigation Agent; you can be both! It simply means including, as far
            as is reasonable, your family in preparation efforts. Parents have
            an additional responsibility as children are a vulnerable segment of
            the population.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Resilient Family"
            src="https://images.unsplash.com/photo-1625434132728-97518a6a3c86?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80 "
          />
          <br />
          <p>
            Not everyone is in a situation where they are able to, or desire to
            respond to a disaster. Recognizing that is a strength in and of
            itself. The mirror side of physically responding to a disaster is to
            ensure that you don't become a casualty. Prepare yourself and your
            family as best you can so that you can tell any emergency crews that
            'we're good here!' Providing support during a disaster doesn't have
            to be physical or financial in nature. Offering support in the form
            of kind words, for example. Every little bit helps.
          </p>
          <br />
          <p>
            Efforts include:
            <ul className="list-disc list-inside mx-4">
              <li>
                Making an effort so both parents/guardians are trained in
                Child-specific First Aid/CPR
              </li>
              <li>
                Including all family members for drills involving localized
                hazards (such as a Wildfire Evacuation, Hurricane Evacuation,
                etc.)
              </li>
              <li>
                Taking into account specific needs for children. This includes
                baby formula, specific foods, medications, special-needs
                accommodations, etc.
              </li>
            </ul>
            <br />
            Like being a Mitigation Agent, if you are striving to make sure your
            family is the one giving the 'all clear' to any responders, then
            you're striving to be a Resiliency Agent/Resilient Family.
          </p>
        </article>
      </div>
    </>
  );
}
