import React from "react";

export default function Contingency() {
  return (
    <div className="page-content">
      <h1>
        Contingency Medical
      </h1>
      <article className="article-content">
        <p>
          Founded in 2022, Contingency Medical offers three different levels of
          medical supply kits for a wide range of emergency situations. In terms
          of medical preparedness, this company fills the gap of customization
          between Jase Medical and Duration Health, and is a fantastic
          middle-of-the-road option.
        </p>
        <br />
        <p>
          Their process to consult with a licensed physician is similar to JASE,
          in that you fill out an online form prior to purchasing the various
          kits. However, Contingency Medical offers multiple options to better
          customize your medical preparedness supplies without any hassle.
        </p>
        <br />
        <p>
          The basic kit, the 'Ready Pack', contains just antibiotics, and is
          priced at $260. The second kit, the 'Go Pack', contains fewer doses of
          antibiotics, but is coupled with other common medications to help
          address a wide range of ailments during a disaster. It's priced at
          $280.
        </p>
        <br />
        <p>
          The final kit, the 'Go Pack Plus' is, as the name suggests, a
          combination of the prior two packs with increased quantities of
          antibiotics plus the additional medications. It's priced at $300.
        </p>
        <br />
        <p>
          Ultimately, Contingency Medical covers the area between the
          standardization of Jase Medical's products, and the extreme
          customization of Duration Health. I personally have their Go Pack
          Plus, and would highly recommend picking up one of their kits to add
          to your medical arsenal.
        </p>
        <br />
        <p>
          I've partnered with Contingency Medical to both support the work
          they're doing, as well as get a discount code, as the kits can be
          pricy for those starting out. The code is optional, but it saves you
          some money and helps support the site and work that I do.
        </p>
        <br />
        <p className="mb-5 font-bold">
          Enter “getprepared” at checkout and receive $15 off.
        </p>
        <br />
        <a
          className="hover:link font-bold"
          href="https://contingencymedical.com/"
          rel="noreferrer"
          target="_blank"
        >
          Link to Contingency Site
        </a>
      </article>
    </div>
  );
}
