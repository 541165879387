import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useScrollDirection } from "../utils/helpers";
import { StandardS, StandardL } from "./subheader/Standard";
import { PrepareS, PrepareL } from "./subheader/Prepare";
import { ProductsS, ProductsL } from "./subheader/Products";

export default function Header() {
  const scrollDirection = useScrollDirection();
  const path = useLocation().pathname;

  return (
    <div
      className={`sticky ${
        scrollDirection === "down" ? "-top-24" : "top-0"
      } transition-all duration-500 z-50`}
    >
      <div className="navbar bg-primary text-slate-100">
        <div className="navbar-start">
          <button className="btn btn-ghost">
            <Link to="/">
              <img
                src={require("../assets/icon/puap_logo_header.png")}
                width="48px"
                height="48px"
                draggable="false"
                alt="Pick Up A Piece Logo"
              />
            </Link>
          </button>
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-primary lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            {/* Small Screens */}
            {(() => {
              if (path.includes("/prepare")) {
                return <PrepareS />;
              } else if (path.includes("/products")) {
                return <ProductsS />;
              } else {
                return <StandardS />;
              }
            })()}
          </div>
        </div>
        <div className="navbar-end hidden lg:flex">
          {/* Large Screens */}
          {(() => {
            if (path.includes("/prepare")) {
              return <PrepareL />;
            } else if (path.includes("/products")) {
              return <ProductsL />;
            } else {
              return <StandardL />;
            }
          })()}
        </div>
      </div>
    </div>
  );
}
