import React from "react";

export default function FirstAid() {
  return (
    <>
      <div className="grid justify-items-center text-left animate-appear">
        <h1 className="text-3xl font-bold text-center text-primary m-3">
          First Aid
        </h1>
        <div className="mb-3 w-3/4 sm:w-1/2 text-zinc-600 dark:text-neutral-content">
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Kits
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3molpUN"
                target="_blank"
                rel="noreferrer"
              >
                Be Smart Get Prepared 100 Piece First Aid Kit
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3Flj447"
                target="_blank"
                rel="noreferrer"
              >
                Be Smart Get Prepared First Aid Kit, 250 Piece Set
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/42ikrwH"
                target="_blank"
                rel="noreferrer"
              >
                Wilderness First Aid Kits
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3qeVf7S"
                target="_blank"
                rel="noreferrer"
              >
                Ever Ready First Aid Adult and Infant CPR Mask Combo Kit
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Advanced Trauma Kit
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3svwXcJ"
                target="_blank"
                rel="noreferrer"
              >
                MediTac Premium IFAK Kit
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Basic Medications
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3Ei4oBF"
                target="_blank"
                rel="noreferrer"
              >
                Amazon Basic Care Extra Strength Pain Relief, Acetaminophen
                Caplets
              </a>
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://amzn.to/30Twaa4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Amazon Basic Care Ibuprofen Tablets, 500 Count
                </a>
              </li>
              <h2 className="text-xl font-bold text-center text-primary my-5">
                Advanced Medications
              </h2>
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://jasemedical.com/?rstr=5078"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jase Medical (Enter “PREPARED10” at the checkout for $10 off.)
                </a>
              </li>
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://contingencymedical.com/?ref=24"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contingency Medical (Enter “getprepared” at checkout for $15 off.)
                </a>
              </li>
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://durationhealth.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Duration Health (Enter “PREPARE1” at checkout to get $50 off.)
                </a>
              </li>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Tourniquet
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/426TETW"
                target="_blank"
                rel="noreferrer"
              >
                C-A-T Tourniquet
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Splint
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3sqMHh8"
                target="_blank"
                rel="noreferrer"
              >
                SAM Rolled Splint 36″, Orange/Blue
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3EmOaXU"
                target="_blank"
                rel="noreferrer"
              >
                SAM Medical Splint Roll,Pink 2 Count
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Bleeding Control
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3Ek2h0e"
                target="_blank"
                rel="noreferrer"
              >
                QuikClot Advanced Clotting Gauze
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3Ef6Ngl"
                target="_blank"
                rel="noreferrer"
              >
                QuikClot Advanced Clotting Gauze(2 Strips)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/30OBnjn"
                target="_blank"
                rel="noreferrer"
              >
                Gauze Bandage Roll with Free Bonus Tape
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/329vFtb"
                target="_blank"
                rel="noreferrer"
              >
                6″ Israeli Emergency Bandage 2 Pack
              </a>
            </li>
          </ul>
          <br />
          <p className="mb-5">
            Affiliate Link Reminder: To keep this site ad-free, the links in the
            store are affiliate-based and rely on cookies. A segment of any
            purchase made after clicking the link (any product), will give a
            small percentage kickback to the site. Thank you for helping keep
            this site ad-free!
          </p>
        </div>
      </div>
    </>
  );
}
