import React from "react";

export default function Flood() {
  return (
    <>
      <div className="page-content">
        <h1>
          Flood
        </h1>
        <article className="article-content">
          <p className="mt-3">
            Floods can be created from multiple sources. These include man-made
            (failed dam) and natural, such as a hurricane or excessive rainfall.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Flood"
            src="https://images.pexels.com/photos/6471946/pexels-photo-6471946.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />
          <br />
          <p>
            The first thing one should do, if you think you're at a risk for
            flooding, is look at a floodplain map. This will give you an idea of
            the risk factor (i.e. how often it floods, and how severe it is.)
            Living downstream of a dam can be considered a risk factor outside
            of a flood plain.
          </p>
          <br />
          <p>
            Evacuation is considered to be the first course of action in a
            flood. You can't stop a flood, and staying safe is the number one
            priority. Floods can be gradual (a river overflowing its banks
            during/after heavy rainfall,) or rapid, such as a flash flood. It's
            important to know which type of flood you're most likely to
            experience in your location.
          </p>
          <br />
          <p>
            Like other disasters, having supplies in case of an evacuation is
            key. The difference with a flood is to prepare your home and
            supplies accordingly. Supplies should be on the 2nd story, or at
            least off the ground. Purchasing inflatable (with water) makeshift
            dams or sandbags can protect your home from a flood if you have time
            before evacuating or if you're unable to leave.
          </p>
          <br />
          <p>
            For a flood, the following steps and supplies may be considered -
          </p>
          <ul className="list-disc list-inside mx-4 space-y-1">
            <li>Be aware of your risk</li>
            <li>
              Ensure there's access to your roof from your attic. Do not assume
              you can just 'break through' the wood!
            </li>
            <li>
              Have essential documents in waterproof containers/off the floor
            </li>
            <li>
              Purchase sanitation supplies such as gaiters, antibacterial wipes,
              bleach, and other cleaning products for clean-up, including masks
              to protect from mold.
            </li>
            <li>Have a communications plan in case you're separated.</li>
          </ul>
          <br />
          <p>
            More specifics are listed on the United States government website:{" "}
            <a
              className="hover:link font-bold"
              href="https://www.ready.gov/floods"
              target="_blank"
              rel="noreferrer"
            >
              https://www.ready.gov/floods
            </a>
          </p>
          <br />
          <p>
            If a flood alert is issued over the radio or by phone, do not delay!
            Seek shelter immediately. Six inches of moving water can knock
            someone off their feet, and one foot (.3m) can lift a vehicle. Do
            not wade through water or drive through it.
          </p>
          <br />
          <p>
            If you're able to take any additional actions before the flood
            hits/evacuating, do the following.
          </p>
          <ul className="list-disc list-inside mx-4 space-y-1">
            <li>Pre-position supplies in the car</li>
            <li>
              Turn off all power to your home (as long as the water hasn't hit
              yet)
            </li>
            <li>Unplug all ground-level appliances</li>
          </ul>
          <br />
          <p>
            If an evacuation is ordered, leave <strong>immediately.</strong>
          </p>
          <br />
          <p>
            If you're in your home and the water is visibly rising, and
            evacuation isn't possible, get to the highest point you can.{" "}
            <strong>
              Do not go into an attic unless there is roof access.
            </strong>{" "}
            There are many instances of trapped people drowning in their attics.
          </p>
          <br />
          <p>
            When it's safe to return home, wear heavy gloves and boots when
            sorting through items. Anything that was immersed in water is likely
            to be destroyed. Floodwater is horrifically contaminated, and the
            home is likely to suffer extensive mold and mildew damage (so wear a
            mask.)
          </p>
          <br />
          <p>
            Be extremely careful entering your home. Ensure the power is turned
            off, and have a licensed electrician inspect things to ensure it's
            safe to turn on the power.
          </p>
          <br />
          <p>
            Wear heavy gloves when sorting through items. Any items that have
            been immersed in water are likely not able to be saved for this
            reason. Wearing respiratory protection is also advised, as there
            will likely be large amounts of mold and mildew. Children, along
            with individuals who have allergies or sensitivities should not
            spend a long time in areas that smell of mold and mildew.
          </p>
          <br />
          <p className="mb-5">
            The flood maps available on{" "}
            <a
              className="hover:link font-bold"
              href="https://msc.fema.gov/portal/home"
              target="_blank"
              rel="noreferrer"
            >
              FEMA's website
            </a>
            , are color-coded to indicate the risk of a flood in your area, as
            per the legend.
          </p>
        </article>
      </div>
    </>
  );
}
