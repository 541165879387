import React from "react";

export default function About() {
  return (
    <div className="min-h-screen">
      <div className="page-content">
        <h1>
          About
        </h1>
        <article className="article-content">
          <p>
            In short, Pick Up A Piece is an informal, non-centralized, and
            non-political organization formed to promote personal preparedness
            and standardize a level of readiness.
            <br />
            <br />
            After seeing people being pulled apart by a society and world that
            revels in division, I (Bunker John) decided to try and combat that
            with this loose organization. Many individuals are looking for ways
            to better prepare themselves and their families. But there are so
            many voices, it's easy to get lost in them (I certainly have at
            times.)
            <br />
            <br />
            This site serves as an informational hub to gather individuals
            together in becoming more prepared. The first line of defense
            against a crisis, big or small, is an individual, a family, and a
            community. Pick Up A Piece serves as a uniting factor in preparing
            and responding to a crisis, a uniting factor in which political
            view, race, religion, or other factors are not barriers. It aims to
            point them in the right direction, to offer both guides for
            preparing for a crisis as well as direct links to products that are
            well-researched and widely relied on.
            <br />
            <br />
            Many people appreciate the idea of belonging to something. That urge
            to belong drives many to areas of good influence, but also bad.
            Since many individuals gravitate towards belonging to something, why
            not have that be a force for good? Perhaps, in time, you'll see
            individuals with the organization's logo helping out after a
            disaster, or even simply in your day-to-day life. As described on
            the 'Become a Mitigation Agent' page, it's similar to a Boy Scout
            oath. You are personally ascribing to the idea that you will help to
            the best of your ability irrespective of race, income, religion, or
            political view. We're all the same when disasters hit. The goal is
            to unite us in that fellow status.
            <br />
            <br />
            I wish to make this clear:
            <br />
            This is not a militia.
            <br />
            No illegal activities will be condoned.
            <br />
            <br />
            The advice on this site is to guide individuals to operate under
            their local laws, including not interfering with official emergency
            responders or other organizations. Should emergency services be
            unavailable, this site and organization aims to have prepared
            members to respond to the best of their trained ability.
            <br />
            <br />
            The quote that kick-started this venture is as follows, from Tom
            Clancy's 'The Division.' The upcoming threat rating system and
            'mitigation agent' designations are simply a mostly tongue-in-cheek
            nod to this origin.
            <br />
            <br />
          </p>
          <div className="flex justify-center">
            <blockquote className="mx-5 px-5 border-l-2 border-zinc-900 dark:border-zinc-400">
              <i>
                <p>
                  "It's hard to watch something you love destroy itself, to see
                  it fall apart. Disaster always feels so distant, detached,
                  someone else's struggle in some far away place. It's not until
                  it's in our city, at our doors, do we realize just how fragile
                  we are, all of us, all of this. And when it hit, when it came
                  down, it came down hard. And we fell. We buried ourselves in
                  anger, fear and the instinct to survive. And the real truth is
                  no one watched. No one saw or wanted to see.
                  <br />
                  <br />
                  Tragedy is invisible. People turn away from it, they run from
                  it if they can. And it's hard knowing you belong here. That
                  your purpose lies amongst all this pain. But someone's gotta
                  be there. To pick it up, to push back, to put the first piece
                  back together.
                  <br />
                  <br />
                  To put us all back together."
                  <br />
                  <br />
                  - Tom Clancy's The Division
                </p>
              </i>
            </blockquote>
          </div>
          <br />
          <p>
            This is Pick Up A Piece. Putting the world back together when it all
            falls apart.
          </p>

          <h2>
            Who is Bunker John?
          </h2>

          <p>
            Bunker John is, well, me! It's a pseudonym I have used online for
            years as I try my best to guide people towards self-reliance on
            various platforms. (Imgur/Reddit, mainly.) It's the username I
            utilized during the 2 years of running a COVID-19 newsletter on
            Imgur.
            <br />
            <br />
            So, why trust my recommendations?
            <br />
            <br />
            Formally, I have a graduate degree in Emergency Management/Disaster
            Preparedness. I've also written multiple articles for a major
            Outdoors/Solar System company instructing others how to prepare for
            a disaster, along with having covered the COVID-19 Pandemic for 2
            years with a regular, non-political newsletter on Imgur. My other
            Disaster/Emergency Management experience/certifications include:
            <br />
            <br />
          </p>
          <ul className="list-disc list-inside mx-4">
            <li>Experience as a Wildland Fire Dispatcher/Fire Lookout</li>
            <li>
              Experience as an Emergency Planning Specialist (Health Department)
            </li>
            <li>
              Multiple IS-FEMA Classes completed, including the Professional
              Development Series.
            </li>
            <li>Certified Firearms Instructor (Pistol/Rifle/Shotgun)</li>
            <li>Certified Chief Range Safety Officer</li>
            <li>Certified Wilderness First Responder</li>
          </ul>
          <p>
            <br />
            On a less formal note, I've been involved in self-reliance/prepping
            for over a decade, and have done hundreds of hours of research for
            the various preparedness steps, products, and ways of thinking I now
            try to pass along.
            <br />
            <br />
            While I'd be honored if you trusted both the how-to prepare guides,
            along with my other recommendations, I hope you have a bit of
            skepticism. That's healthy! I'm just one of <i>many</i> individuals
            on the internet giving their advice, and there are many.
            <br />
            <br />
            While the suggested/linked products are the result of hours of
            research and quite a bit of waffling back and forth for my own
            supply list, the items are ultimately for <i>you</i>, and I hope
            everyone explores various options, as nobody's self-reliance journey
            is 100% the same. I simply aim to offer a general guide, both
            recommending products I personally trust, and also providing a
            baseline if you choose to research alternatives.
            <br />
            <br />
            I do want to clarify that I do not offer any products I, myself
            would not use, own, or recommend for myself or family and friends.
            If I am ever paid to promote a singular product/brand in a post, I
            will not conceal that in the slightest. Products in the 'Store'
            section of the site are ones I feel comfortable using myself.
            <br />
            <br />
            So, thank you for reading, and if you so choose to support the
            efforts of this Site and Organization, welcome to Pick Up A Piece.
          </p>
        </article>
      </div>
    </div>
  );
}
