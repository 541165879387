import React from "react";

export default function PPE() {
  return (
    <>
      <div className="grid justify-items-center text-left animate-appear">
        <h1 className="text-3xl font-bold text-center text-primary m-3">
          Personal Protective Equipment
        </h1>
        <div className="mb-3 w-3/4 sm:w-1/2 text-zinc-600 dark:text-neutral-content">
          <h2 className="text-xl font-bold text-center text-primary my-5">
            Pandemic Kit
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://www.firstaidglobal.com/?ref=lg10sv4i4j"
                target="_blank"
                rel="noreferrer"
              >
                General Link to First Aid Global
              </a>
              <p>(Coupon Code for 10% off site-wide= BUNKERJOHN)</p>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://www.firstaidglobal.com/product-page/1-person-contagion-kit?ref=lg10sv4i4j"
                target="_blank"
                rel="noreferrer"
              >
                HazMat-Nuclear-Pandemic-Contagion Kit for One Person
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://www.firstaidglobal.com/product-page/3-person-family-contagion-kit?ref=lg10sv4i4j"
                target="_blank"
                rel="noreferrer"
              >
                HazMat-Nuclear-Pandemic-Contagion Kit for Three Persons
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
           Masks
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3phuiRP"
                target="_blank"
                rel="noreferrer"
              >
               3M Personal Protective Equipment Particulate Respirator 8210, N95
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3H8VJU5"
                target="_blank"
                rel="noreferrer"
              >
              FANGTIAN N95 Mask NIOSH Certified
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3mofvTy"
                target="_blank"
                rel="noreferrer"
              >
              3M Half Facepiece Reusable Respirator 6200
              </a>
            </li>
          </ul>
          <h3 className="text-lg font-bold text-left text-primary my-5">
            3m Filters
          </h3>
          <ul>
          <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3EmOwhc"
                target="_blank"
                rel="noreferrer"
              >
              3M 2091 P100 Particulate Filter
              </a>
            </li>
          </ul>
          <h3 className="text-lg font-bold text-left text-primary my-5">
            Other
          </h3>
          <ul>
          <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3J9FZ52"
                target="_blank"
                rel="noreferrer"
              >
              DuPont TY127S Disposable Elastic Wrist, Ankle & Hood White Tyvek Coverall Suit 1428
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-5">
          CBRN (Chemical, Biological, Radiological, Nuclear) Mask Options
          </h2>
          <ul>
          <li>
              <a
                className="hover:link font-bold"
                href="https://bestsafetyapparel.com/products/mestel-sge-150-gas-mask"
                target="_blank"
                rel="noreferrer"
              >
             SGE 150 Mask
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3H6tGEr"
                target="_blank"
                rel="noreferrer"
              >
             MIRA 6M Mask
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3FjrbOR"
                target="_blank"
                rel="noreferrer"
              >
              MIRA 40mm Filter (20 year shelf life)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://www.mirasafety.com/collections/frontpage"
                target="_blank"
                rel="noreferrer"
              >
             MIRA Home Products Link
              </a>
            </li>
          </ul>
          <br />
          <p className="mb-5">
            Affiliate Link Reminder: To keep this site ad-free, the links in the
            store are affiliate-based and rely on cookies. A segment of any
            purchase made after clicking the link (any product), will give a
            small percentage kickback to the site. Thank you for helping keep
            this site ad-free!
          </p>
        </div>
      </div>
    </>
  );
}
