import React from "react";

export default function Landing() {
  return (
    <>
      <div className="grid justify-items-center text-left animate-appear">
        <h1 className="text-3xl font-bold text-center text-primary m-3">
          Products
        </h1>
        <article className="mb-3 w-3/4 sm:w-1/2 text-zinc-600 dark:text-neutral-content">
          <p className="mt-5">
            As a foreword, all the items I offer/support I either own, have
            owned, or am comfortable recommending to my friends and family. If
            there's a product specifically showcased due to a company wanting to
            promote it, it'll be clearly identified and I'll give my honest
            thoughts on it.
          </p>
          <br />
          <p>
            All of the items on this page are part of affiliate programs or
            direct merch. As I aim to keep this website ad-free, this is the
            primary method to do so. Clicking on any of the links utilizes an
            affiliate cookie, and then any purchases you make (even if not the
            exact product,) while that cookie is active, helps support the site
            through amazon or other companies (such as Mira Safety).
          </p>
          <br />
          <p>
            The Redbubble link is to purchase any merchandise with the
            Organization logo, and the Patreon offers a way to steadily support
            the site (and gives out merchandize rewards.) The Ko-Fi link is for
            one-time donations.
            <ul className="list-disc list-inside">
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://ko-fi.com/pickupapiece"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ko-fi
                </a>
              </li>
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://www.patreon.com/pickupapiece"
                  target="_blank"
                  rel="noreferrer"
                >
                 Patreon
                </a>
              </li>
              <li>
                <a
                  className="hover:link font-bold"
                  href="https://www.redbubble.com/people/Pick-Up-A-Piece/shop?asc=u"
                  target="_blank"
                  rel="noreferrer"
                >
                  Redbubble
                </a>
              </li>
            </ul>
          </p>
          <br />
          <p>
            Likewise, discount codes serve the same purpose and give a kickback
            if used (and provide a nice bit of money-saving!)
          </p>
          <br />
          <p>
            If there's a specific product you feel should be showcased, please
            feel free to reach out.
          </p>
        </article>
      </div>
    </>
  );
}
