import { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkMode from "../hooks/useDarkMode";

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkMode();
  const [mode, setMode] = useState(colorTheme === "light" ? true : false);

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setMode(checked);
  };

  return (
    <>
      <DarkModeSwitch
        checked={mode}
        onChange={toggleDarkMode}
        size={30}
      />
    </>
  );
}
