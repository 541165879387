import React from "react";

export default function Landing() {
  return (
    <>
      <div className="page-content">
        <h1>
          Prepare for a Crisis
        </h1>
        <article className="article-content">
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Crisis"
            src="https://images.pexels.com/photos/4170461/pexels-photo-4170461.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2
"
          />
          <br />
          <p className="text-center my-2">
            First, take a deep breath.
            <br />
            <br />
            Did that? Good, now let's begin.
          </p>
          <p className="my-5">
            The world likely isn't going to end tomorrow, but there's probably
            some event that has you worried. So, where to start?
            <br />
            <br />
            Before buying, planning, or any other steps take place, first
            evaluate what exactly you're wanting to prepare for. It can be
            overwhelming seeing many potential disasters, but you need to start
            small.
            <br />
            <br />
            While planning bunkers and a year's supply of food is more fun, one
            should prepare for the more common threats first.
            <br />
            <br />
            These include:
          </p>

          <ul className="list-disc list-inside mx-4">
            <li>A house fire</li>
            <li>Financial distress (job loss, emergency car repair, etc.)</li>
            <li>
              Local disasters (hurricane area, tornadoes, wildfires, etc.)
            </li>
          </ul>
          
          <p className="my-5">
            A fantastic resource on where to start is{" "}
            <a
              href="https://www.ready.gov"
              target="_blank"
              rel="noreferrer"
              className="text-primary font-bold hover:underline"
            >
              ready.gov
            </a>{" "}
            for a basic supply list (the Store tab has links to many
            suggestions.)
            <br />
            <br />
            Preparedness is built on a few foundational pillars:
          </p>
          <ol className="list-decimal list-inside mx-4">
            <li>Food</li>
            <li>Water</li>
            <li>Communications</li>
            <li>First Aid</li>
            <li>Self-defense/personal health (including fitness)</li>
          </ol>
          <p>
            <br />
            <br />
            Most supplies are, in general, an extension of one of those five
            areas.
            <br />
            <br />
            FEMA recommends two weeks of supplies. If that seems overwhelming,
            just start with three days! The options on the left menu further
            break down each of those five areas to start you off on your
            self-reliant journey. The Store Tab has some recommended products
            for the foundational pillars of preparedness as well.
            <br />
            <br />
            For more information, and a more comprehensive list involving
            disasters and how to prepare for them, I would urge you to visit{" "}
            <a
              href="https://www.ready.gov"
              target="_blank"
              rel="noreferrer"
              className="text-primary font-bold hover:underline"
            >
              ready.gov
            </a>
            . This site only covers some of the major potential disasters, and
            is not comprehensive.
          </p>
        </article>
      </div>
    </>
  );
}
