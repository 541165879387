import React from "react";

export default function NuclearDisaster() {
  return (
    <>
      <div className="page-content">
        <h1>
          Nuclear Disaster
        </h1>
        <article className="article-content">
          <p className="mt-3">
            A nuclear disaster or attack is a looming specter for many. While
            unlikely, a nuclear event is certainly survivable and worth
            preparing for. Rather than re-hashing what to do in case of a
            disaster, I'd urge you to read the following two guides. They are
            far more comprehensive than what I can say on a single page.
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Fallout Shelter"
            src="https://images.unsplash.com/photo-1597451773417-891ce13364ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80 "
          />
          <br />
          <p>
            What to do if a Nuclear Disaster is Imminent:{" "}
            <a
              className="hover:link font-bold"
              href="http://www.ki4u.com/guide.htm"
              target="_blank"
              rel="noreferrer"
            >
              http://www.ki4u.com/guide.htm
            </a>
            <br />
            The Good News about Nuclear Destruction:{" "}
            <a
              className="hover:link font-bold"
              href="http://www.ki4u.com/goodnews.htm"
              target="_blank"
              rel="noreferrer"
            >
              http://www.ki4u.com/goodnews.htm
            </a>
          </p>
          <br />
          <p>
            After a blast, the primary danger is radiation in the form of
            fallout. It comes in three forms: Alpha, Beta, and Gamma. Radiation
            is blocked and/or slowed by mass. The denser the better.
          </p>
          <br />
          <p>
            Fallout is a byproduct of a nuclear blast; dirt and debris are
            sucked up into the fiery column and then distributed down in
            radioactive dust over a wide area. A basement is an ideal shelter in
            case of fallout.
          </p>
          <br />
          <p>Basic types of Radiation in a Nuclear Disaster:</p>
          <ul className="list-disc list-inside mx-4 space-y-1">
            <li>
              <strong>Alpha:</strong> Least amount of penetrating power, but
              very dangerous if inhaled. Can be blocked by clothing, skin, or a
              piece of paper.
            </li>
            <li>
              <strong>Beta:</strong> Can penetrate through a few inches of dirt.
              Requires sheltering.
            </li>
            <li>
              <strong>Gamma:</strong> The most dangerous, as it can penetrate
              through feet of concrete. Requires extensive sheltering, but
              ideally relocating.
            </li>
          </ul>
          <br />
          <p>
            Fallout generally follows the 7-10 rule. For every 7 hours, the
            level of radiation decreases by a factor of 10. So, within a week,
            99% of the radiation will be gone. Ideally, sheltering in place for
            2 weeks is ideal.
          </p>
          <br />
          <p>
            Having a way to pick up the radiation levels is, as one might
            expect, essential. Personally, I utilize calibrated CDV-715 Geiger
            Counters. KI4U is the only service in the U.S. that has a lab to
            calibrate these 60-year-old instruments. While not as accurate as
            digital ones, they're resistant/immune to the EMP put off by a
            nuclear blast, and built like a tank. A cheaper option is the
            NukAlert keychain. Both of these products only detect Gamma
            radiation, however, and at extremely high ranges. The CDV-700 has a
            lower range and can detect Beta/Gamma radiation, but can get
            saturated by higher levels (hence why I utilize both.)
          </p>
          <br />
          <p>
            It's important to note that if shopping for an additional radiation
            meter, you look at the maximum levels for detection. The vast, vast
            majority don't reach levels present after a nuclear attack (measured
            in Micro/Mili/Nano Sieverts.) Digital meters will be more accurate,
            but more complex and vulnerable to an EMP unless you pay a
            significant amount.
          </p>
          <br />
          <p>
            I'd recommend the NukAlert device as a starting point. It's simple,
            cheaper than other options, and will work in a pinch.
          </p>
          <br />
          <p>
            If for whatever reason, you have to leave the shelter of your home
            before the fallout has dissipated, a mask is essential to prevent
            the particles from being inhaled. Fallout is fine (or large)
            particles of dust. An N95 is a good mask to keep you safe (with
            goggles), but a full-face respirator/filter that hits the 99.95%
            mark for filtration is ideal, such as the MIRA Safety Masks on the
            store page.
          </p>
          <br />
          <p>
            As the guides explained, a nuclear disaster is a horrific event, but
            survivable with a few basic preparations. After such an event,
            having a stockpile of general supplies would greatly help an already
            horrible situation before help arrives.
          </p>
          <br />
          <p className="mb-5">
            In case of multiple detonations/disasters, that requires an
            additional layer of preparedness to be self-sufficient after the
            fallout subsides, as infrastructure would likely be destroyed. Doing
            so is beyond the scope of this initial page. Being prepared for 1+
            years of no infrastructure/utilities would be adequate preparation
            for that event.
          </p>
        </article>
      </div>
    </>
  );
}
