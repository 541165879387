import React from "react";

export default function JASE() {
  return (
    <div className="page-content">
      <h1>
        JASE Medical
      </h1>
      <article className="article-content">
        <p>
          Founded in 2020 by a board-certified physician, JASE medical is a
          simple, straightforward way to legally obtain an emergency supply of
          antibiotics.
        </p>
        <br />
        <p>
          Prior to the emergence of telehealth, there were few options for
          preparedness-minded individuals seeking to obtain antibiotics. JASE
          offers a kit that comes with 5 medications, a carrying case, and a
          guide booklet to instruct you of how to use the antibiotics in case of
          an emergency for $270. They additionally offer the{" "}
          <strong>Jase Daily program</strong>, which is a way to obtain a year's
          supply of your critical medications in case of a disruption.
        </p>
        <br />
        <p>
          The process to get the kit is very simple. Each customer goes through
          an online consultation/evaluation, submits the form, and
          board-certified physicians then write the prescriptions.
        </p>
        <br />
        <p>
          Be it a natural disaster, supply chain failure, or complete
          infrastructure collapse, the importance having a supply of antibiotics
          and the knowledge of how to use them cannot be overstated.
        </p>
        <br />
        <p>
          As we've seen over the past few years, things can turn nasty at the
          flip of a coin. Adding JASE's kit to your medical arsenal (along with
          general medical training,) is a fantastic way to round out your
          emergency preparations.
        </p>
        <br />
        <p>
          I've partnered with JASE to both support the work they're doing, as
          well as get a discount code, as the kits can be pricy for those
          starting out. The code is optional, but it saves you some money and
          helps support the site and work that I do.
        </p>
        <br />
        <p className="mb-5 font-bold">
          Enter “PREPPERMEDS10” at the checkout to receive $10 off.
        </p>
        <br />
        <a
          className="hover:link font-bold"
          href="https://jasemedical.com/"
          rel="noreferrer"
          target="_blank"
        >
          Link to JASE Site
        </a>
      </article>
    </div>
  );
}
