import React from "react";
import { Link } from "react-router-dom";

export default function FALanding() {
  return (
    <>
      <div className="page-content">
        <h1>
          First Aid
        </h1>
        <article className="article-content">
          <p className="mt-3">
            First aid is something that we all have utilized in our lives.
            Whether from a minor cut to a severe wound, accidents happen.
            Knowing how to treat those injuries and having the equipment on hand
            is another part of being prepared for what life throws your way.
          </p>
          <br />
          <p>
            Getting certified in First Aid and CPR is a fantastic way to
            increase your preparedness, along with having one, or multiple first
            aid kits tailored to your needs. This may include adding
            prescription medications, or additional bandages if, for example,
            you deal with sharp tools on a regular basis at home (such as in a
            garage.)
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Fist aid Kit"
            src="https://images.pexels.com/photos/5673523/pexels-photo-5673523.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <p>
            For an additional level of training, I recommend (and personally
            have,) certification as a Wilderness First Responder. It's meant for
            backwoods first aid when help isn't coming.
          </p>
          <br />
          <p>
            There are multiple resources as well to be familiar with. The book
            When There Is No Doctor is a great read and a way to increase your
            knowledge of treating injuries when there isn't help around.
          </p>
          <br />
          <p>
            However, you should default to seeking out a primary care physician
            and advanced medical care if at all possible. At-home remedies don't
            replace advanced care in a hospital or doctor's office. But having
            the knowledge to keep yourself or someone else stable is a key part
            of being prepared.
          </p>
          <br />
          <p className="mb-3">
            The companies listed below offer antibiotic/medication kits for
            purchase prior to a disaster. For further information, please click
            their respective links.
          </p>
        </article>
        <div className="flex flex-row mb-5 space-x-2 sm:space-x-8 md:space-x-20 text-zinc-600 dark:text-neutral-content">
          <Link to="jase-medical">
            <button className="btn hover:bg-primary hover:text-base-100 font-bold">
              JASE Medical
            </button>
          </Link>
          <Link to="contingency-medical">
            <button className="btn hover:bg-primary hover:text-base-100 font-bold">
              Contingency Medical
            </button>
          </Link>
          <Link to="duration-medical">
            <button className="btn hover:bg-primary hover:text-base-100 font-bold">
              Duration Health
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
