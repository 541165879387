import React from "react";

export default function Hurricane() {
  return (
    <>
      <div className="page-content">
        <h1>
          Hurricanes
        </h1>
        <article className="article-content">
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Hurricane"
            src="https://images.pexels.com/photos/76969/cold-front-warm-front-hurricane-felix-76969.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />
          <p>
            <br />A hurricane is defined as a cyclone that forms in the Pacific
            Ocean and has winds of over 74 miles (119 kilometers) per hour. The
            storm will have strong winds, intense rain, and a potential storm
            surge (a wave of water ahead of the storm that is pushed by the
            winds).
          </p>
          <br />
          <p>
            Thankfully, the National Hurricane Center monitors the storms and
            provides a significant warning before a storm hits. The advance
            notice ranges from a few days to a few weeks and gives time for
            individuals, families, and governments to prepare.
          </p>
          <br />
          <p>
            Hurricane season typically runs from June to November in the
            Pacific, and about the same time in the Atlantic Ocean (hurricanes
            that form in the Atlantic are called typhoons.) The south/southeast
            seaboard of the United States is typically the area affected by
            hurricanes. Specifically, the following states in the U.S. are the
            ones normally impacted -
          </p>
          <br />
          <ul className="list-disc list-inside mx-4">
            <li>Florida</li>
            <li>Texas</li>
            <li>Louisiana</li>
            <li>North/South Carolina</li>
          </ul>
          <br />
          <p>
            Hurricanes are rated on a scale of 1 to 5. Category 1 is rated as
            the least severe, with a max wind speed of 95mph/152kph. A Category
            5 is rated as the most severe, with a max wind speed of
            156mph/251kph+
          </p>
          <br />
          <p>
            Hurricanes can strengthen rapidly. If one is approaching, you should
            be tuned into the weather daily at the very least. A Cat. 1 can
            strengthen to a Cat 3 or 4 within days.
          </p>
          <br />
        </article>
        <img
          className="my-5"
          src={require("../../../assets/images/hurricane_speed_chart.jpg")}
          alt="Hurricane Speed Chart"
        />
        <br />
        <article className="article-content">
          <p>
            The primary dangers from Hurricanes are flying debris, and the storm
            surge. Secondary dangers can include damage to infrastructure. As
            with other disasters, getting out of the path of a hurricane is the
            best option. While it's possible to ride out a lower-rated
            hurricane, evacuation orders should not be discounted.
          </p>
          <br />
          <p>
            It's key to get supplies regardless before the storm hits. Stores
            will be emptied by those who didn't think ahead.
          </p>
          <br />
          <p>
            Supplies for a hurricane are extremely similar to that for an
            extended power outage. They include:
          </p>
          <ul className="list-disc list-inside mx-4">
            <li>
              1-2 weeks of non-perishable food, water, and essential medications
            </li>
            <li>First aid</li>
            <li>Key documents</li>
            <li>Flashlight</li>
            <li>Heavy-duty gloves for moving debris</li>
            <li>Thick gaiters for wading through contaminated water</li>
            <li>
              Plywood cut-outs for windows (or better, hurricane shutters)
            </li>
            <li>
              Communications methods (walkie talkies, battery-powered radio, and
              cell phone battery packs/chargers.
            </li>
            <li>Spare food and items for pets</li>
            <li>
              Potential solar backup battery bank to charge
              phones/radios/flashlight batteries.
            </li>
            <li>Backup gasoline/additional fuel for a generator</li>
          </ul>
          <br />
          <p>
            The following is a more comprehensive list from FEMA (Federal
            Emergency Management Agency.) It offers more in-depth descriptions
            as well as potential printouts of what to do before, during, and
            after a hurricane hits.
            <br />
            <br />
            <a
              className="hover:link font-bold"
              href="https://www.fema.gov/pdf/hazard/hurricane/2010/hurricane_week_preparedness_factsheet_ready.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://www.fema.gov/pdf/hazard/hurricane/2010/hurricane_week_preparedness_factsheet_ready.pdf
            </a>
          </p>
          <br />
          <p>
            A hurricane will bring heavy rain, a potential storm surge, and
            strong winds as it nears land. The following are some basic steps to
            perform as landfall nears.
          </p>
          <ul className="list-disc list-inside mx-4">
            <li>
              Monitor the storm's progress via phone, website, radio, or other
              media
            </li>
            <li>Board up/cover windows (or install storm shutters)</li>
            <li>
              Have go-bags (bug-out-bags) packed and ready to go with other
              supplies if an evacuation is ordered
            </li>
            <li>
              Listen for evacuation orders, and don't delay if one is issued!
            </li>
            <li>
              Be aware that if sent to a shelter for safety, they may not have
              spare power. Pack a spare set of battery packs to charge
              phones/other items
            </li>
            <li>
              Storm shelters may not allow pets. Have this in mind when
              evaluating whether to evacuate before a storm or wait for a
              potential order to do so
            </li>
            <li>
              Do not assume you can ride it out. A hurricane can easily destroy
              homes and buildings with winds, flying debris, and flooding/a
              storm surge. Ignoring evacuation orders puts first responders at
              risk if you need help later. Operate on the better-safe-than-sorry
              principle in terms of evacuation
            </li>
          </ul>
          <br />
          <p>
            After a hurricane, the local neighborhood may be anywhere from
            mildly inconvenienced, to utterly unrecognizable. Utilities will
            likely be down for a short, or extended period. Treat all downed
            wires as live, and tune into local news stations for recovery
            efforts.
          </p>
          <br />
          <p className="mb-5">
            As communications may be spotty, having a communications plan to
            stay in touch with your family with out-of-state contacts is key, as
            is adding walkie-talkies to your kits for when you're helping around
            the neighborhood. Help your neighbors recover. A simple way is to
            run an extension cord from a generator to help charge other's
            devices.
          </p>
        </article>
      </div>
    </>
  );
}
