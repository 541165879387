import React from "react";

export default function Water() {
  return (
    <>
      <div className="page-content">
        <h1>
          Water Storage
        </h1>
        <article className="article-content">
          <p className="mt-5">
            By and large, water is more important than food to store for an
            emergency. The general recommendation is 1 gallon (3.7L) per person,
            per day. That amount varies based on individuals, climate, and
            levels of exertion.
            <br />
            <br />
            While not intended for long-term storage, having a pack of
            disposable water bottles is generally a good idea. For bulk water
            storage, there are a few options -
          </p>
          <br />
          <ol className="list-decimal list-inside ml-5">
            <li>
              Buying and rotating disposable water packs:
              <br />
              This is generally not recommended due to the price, environmental
              impact, and need to constantly be used and replaced. (Not intended
              for long-term storage.)
            </li>
            <br />
            <li>
              Bulk water storage:
              <br />
              Having a dedicated water container is a bulky but effective way to
              store water. I personally use Aqua-Tainers that are 7 Gallons
              (26.5L). For individuals with time and the space to do so,
              55-gallon (208L) barrels are a bulky but effective way to store
              large amounts of water.
            </li>
          </ol>
          <br />
          <img
            className="object-scale-down w-96 mx-auto"
            alt="Water Storage"
            src="https://images.pexels.com/photos/12496883/pexels-photo-12496883.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 "
          />
          <br />
          <p>
            Water storage should be kept in a cool location and out of the sun.
            Heat and Light are the two recipes for growing all sorts of nasties
            in a water supply.
            <br />
            <br />
            Personal note: I found that out the hard way when I didn't bleach my
            secondary containers as often as I should have, being a Fire
            Lookout. Algae growth can make you feel horrific!
          </p>
          <br />
          <p>
            Now, water is heavy, so be sure to purchase containers you can lift
            to refill and rotate. Water that is filled from the tap doesn't need
            to be treated (if you are in a location that treats the municipal
            water supply.) Rotating the water every 6 months to a year is
            completely fine. If you don't want to rotate the supply, you can buy
            water treatment options to increase that time to a few years (or add
            the required amount of bleach, 1 tablespoon/gallon.)
            <br />
            <br />
            If you are filling your dedicated storage container from a water
            source without municipal additives (such as from a well, etc,) then
            treating the water with bleach or other additives before storage is
            a must to prevent algae growth.
          </p>
          <br />
          <p>
            If you want to store water in your car for an emergency, I recommend
            lifeboat rations. They are made for fluctuating temperatures (both
            extreme heat and freezing temperatures.) They have a shelf life of
            up to (but realistically, more than) 5 years.
            <br />
            <br />
            I would not recommend storing bottled water in your car unless it is
            regularly used and otherwise rotated. The sunlight and temperatures
            break down the plastic containers.
            <br />
            <br />
            <span className="font-bold">Personal note:</span>
            <br />
            I found a bottle that had been in my car for a year or two. It
            looked like a snow globe due to all of the floating plastic
            particles that had been shed from the degrading bottle.
            <br />
            <br />
            <span className="font-bold">At Home/Emergency Use:</span>
            <br />
            After hours of trying to find a filter that was NSF certified, I
            concluded that, while the Lifestraw isn't NSF certified, their
            contracts and utilization with the United Nations speak for
            themselves. There are very few filters that have such certifications
            within a feasible price range, and therefore, Lifestraw was what I
            finally settled on.
          </p>
          <br />
          <p className="mb-5">
            For at-home, water filtration options, you have a variety of options
            including Brita filters for common contaminants/taste. I personally
            use a Lifestraw Home Dispenser (the same method as a Pitcher, both
            have activated carbon filters) and plan on combining that with a
            Family/Community option (for viruses, bacteria, cysts,) in case of
            an emergency.
            <br />
            <br />
            If the Lifestraw filters can perform in horrific conditions when
            used for charity work, that is good enough for me in case of an
            emergency, and they have an indefinite shelf life.
            <br />
            <br />
            For camping or less-frequent use, I’d recommend a big-brand option,
            such as MSR, Sawyer, or Katelyn.
            <br />
            <br />
            Additional water storage information:{" "}
            <a
              className="hover:link font-bold"
              href="https://www.ready.gov/water"
              target="_blank"
              rel="noreferrer"
            >
              https://www.ready.gov/water
            </a>
          </p>
        </article>
      </div>
    </>
  );
}
