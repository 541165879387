import React from "react";

export default function Communications() {
  return (
    <>
      <div className="page-content">
        <h1>
          Communications
        </h1>
        <article className="article-content">
          <p className="mt-5">
            Communications during a crisis is an often-overlooked area of
            preparedness. During a crisis, cell phone towers may be overloaded,
            or your device run out of battery. Having a Family Communications
            Plan can put your mind at ease if, for whatever reason, you're
            separated.
          </p>
          <br />
          <p>
            This involves having a local and out-of-state contact, as well as
            hard copies of phone numbers and important information. During a
            crisis, texts may get through when a call won't; the network will
            likely be overloaded. The same with an out-of-state call; it may go
            through.
          </p>
          <br />
          <a
            className="hover:link font-bold"
            href="https://www.ready.gov/sites/default/files/2020-03/ready_family-communications-plan_kids.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Ready.gov document
          </a>
          <br />
          <br />
          <p>
            In terms of providing your own communications during a severe
            crisis, having some heavy-duty walkie-talkies ready to go may be
            appropriate.
          </p>
          <br />
          <p>
            HAM Radio (Amateur Radio) is another viable option for those who
            want to take an extra step. There are 3 levels, each with increasing
            transmission power and available frequencies to broadcast;
            Technician, General, and Extra. The certification just requires some
            study for the test. The radios can be either handheld (shorter
            range) or have a base station with large antennas (able to reach
            around the world.)
          </p>
          <br />
          <img
            className="object-scale-down h-96 mx-auto"
            alt="Radio"
            src="https://images.pexels.com/photos/6368899/pexels-photo-6368899.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />
          <br />
          <p>
            The study books are sold on Amazon (See the{" "}
            <a
              className="hover:link font-bold"
              href="/products"
              target="_blank"
              rel="noreferrer"
            >
              Products
            </a>{" "}
            tab)
          </p>
          <br />
          <p className="mb-5">
            Ultimately, your communications should allow you to stay in touch
            with family or friends during a crisis. Even if you can't be in
            constant contact, having a plan of what to do is key to being
            prepared and keeping yourself, and your family safe when you
            suddenly can't reach them.
          </p>
        </article>
      </div>
    </>
  );
}
