import React from "react";

export default function Food() {
  return (
    <>
      <div className="grid justify-items-center text-left animate-appear">
        <h1 className="text-3xl font-bold text-center text-primary m-3">
          Food
        </h1>
        <h2 className="text-xl font-bold text-center text-primary my-5">
          Short term food storage, requires little to no preparation
        </h2>
        <div className="mb-3 w-3/4 sm:w-1/2 text-zinc-600 dark:text-neutral-content">
          <h3 className="text-lg font-bold text-left text-primary my-2">
            MRE's
          </h3>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/329oilr"
                target="_blank"
                rel="noreferrer"
              >
                SOPACO Brand
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3qf8vJM"
                target="_blank"
                rel="noreferrer"
              >
                XMRE Brand
              </a>
            </li>
          </ul>
          <h3 className="text-lg font-bold text-left text-primary my-2">
            Energy Bars
          </h3>
          <a
            className="hover:link font-bold"
            href="https://amzn.to/3eaRHOu"
            target="_blank"
            rel="noreferrer"
          >
            Freccia Rossa Market Millennium
          </a>
          <h3 className="text-lg font-bold text-left text-primary my-2">
            Emergency Rations
          </h3>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3yOGdJN"
                target="_blank"
                rel="noreferrer"
              >
                S.O.S. Rations Emergency 3600 Calorie Food Bar
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/32c1mC6"
                target="_blank"
                rel="noreferrer"
              >
                DATREX Emergency Food Ration Bars
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3efJDfm"
                target="_blank"
                rel="noreferrer"
              >
                Red Feather Cremery Canned Butter
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3qjvF1o"
                target="_blank"
                rel="noreferrer"
              >
                B&M Brown Bread, Original
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3ecrUFF"
                target="_blank"
                rel="noreferrer"
              >
                B&M Brown Bread, Raisin
              </a>
            </li>
          </ul>
          <h2 className="text-xl font-bold text-center text-primary my-2">
            Medium/Long Term/Bulk
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/32eNyXe"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House 14 Day Supply
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3yMMaHp"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Essentials Bucket
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3J2adGS"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Lasagna with Meat Sauce
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/30P6jQz"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Creamy Macaroni & Cheese
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3mKv3RR"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Italian Style Pepper Steak
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3pe3tOm"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Biscuits and Gravy
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3J2aQQK"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Beef Stroganoff with Noodles
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3H2QN2V"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Breakfast Skillet
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/32n22UM"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Yellow Curry with Chicken & Rice
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3eeeZTK"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Spaghetti with Meat Sauce
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3qf8zJA"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Pad Thai with Chicken
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3ecjApp"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Scrambled Eggs with Bacon (#10 Can)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3FmsX1u"
                target="_blank"
                rel="noreferrer"
              >
                Mountain House Beef Stroganoff with Noodles (#10 Can)
              </a>
            </li>
          </ul>
          <h2
            className="text-xl font-bold text-center
                     text-primary my-2"
          >
            #10 Canned Goods/Bulk Products
          </h2>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://store.churchofjesuschrist.org/usa/en/new-category/food-storage/5637160355.c"
                target="_blank"
                rel="noreferrer"
              >
                LDS Church Food Storage (Church of Jesus Christ of Latter-Day
                Saints)
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://rainydayfoods.com/"
                target="_blank"
                rel="noreferrer"
              >
                Rainy Day Foods
              </a>
            </li>
          </ul>
          <h3 className="text-lg font-bold text-left text-primary my-2">
            Do-it-yourself materials
          </h3>
          <ul>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3GUf6A2"
                target="_blank"
                rel="noreferrer"
              >
                Dry-Packs 5-Gallon, 20″x30″ Mylar Bags and 2000cc Oxygen
                Absorbers
              </a>
            </li>
            <li>
              <a
                className="hover:link font-bold"
                href="https://amzn.to/3JbyFWe"
                target="_blank"
                rel="noreferrer"
              >
                5 Gallon White Bucket & Lid
              </a>
            </li>
          </ul>
          <br />
          <p className="mb-5">
            Affiliate Link Reminder: To keep this site ad-free, the links in the
            store are affiliate-based and rely on cookies. A segment of any
            purchase made after clicking the link (any product), will give a
            small percentage kickback to the site. Thank you for helping keep
            this site ad-free!
          </p>
        </div>
      </div>
    </>
  );
}
