import React from "react";
import { Link } from "react-router-dom";

export function StandardL() {
  return (
    <>
      <ul className="menu menu-horizontal p-0 animate-appear">
        <li>
          <button type="button">
            <Link to="/about">About</Link>
          </button>
        </li>
        <li>
          <button type="button">
            <Link to="/prepare">Prepare for a Crisis</Link>
          </button>
        </li>
        <li>
          <button type="button">
            <Link to="/general-news">General News</Link>
          </button>
        </li>
        <li>
          <button type="button">
            <Link to="/products">Products</Link>
          </button>
        </li>
      </ul>
    </>
  );
}

export function StandardS() {
  return (
    <>
      <ul
        tabIndex={0}
        className="menu menu-compact dropdown-content mt-3 p-2 shadow rounded-box w-52 bg-secondary"
      >
        <li>
          <button type="button">
            <Link to="/about">About</Link>
          </button>
        </li>
        <li>
          <button type="button">
            <Link to="/prepare">Prepare for a Crisis</Link>
          </button>
        </li>
        <li>
          <button type="button">
            <Link to="/general-news">General News</Link>
          </button>
        </li>
        <li>
          <button type="button">
            <Link to="/products">Products</Link>
          </button>
        </li>
      </ul>
    </>
  );
}
