import React from "react";

export default function General() {
  return (
    <div className="min-h-screen">
      <div className="page-content">
        <h1>
          General News
        </h1>
        <h2>
          Upcoming Features:
        </h2>
        <article className="article-content text-center">
          <p className="font-bold">
            We are currently developing a couple of features to note
          </p>
          <ul className="list-disc list-inside space-y-2 my-4 text-left">
            <li>Email notifications of news and posts.</li>
            <li>Email subscription list</li>
            <li>A dashboard for tracking personal progress and alerts</li>
            <li>Product updates on the store</li>
            <li>Menu re-organization</li>
            <li>New logo artwork</li>
            <li>And more!</li>
          </ul>
          <img
            alt="Stack Of Newspapers"
            src="https://images.pexels.com/photos/167538/pexels-photo-167538.jpeg"
          />
        </article>
      </div>
    </div>
  );
}
